// import { set } from 'mongoose';
import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import './Admin.css'
import { Link } from 'react-router-dom';
import AddEditProds from './AddEditProds';

const AdmindashBoard = () => {
  const [activeSection, setActiveSection] = useState('section1');
  const [activeLetterSection, setActiveLetterSection] = useState('section1');
  const [productCategory, setProductCategory] = useState()
  const [activeSubToggle, setActiveSubToggle] = useState('subToggle1');
  const [loveletters, setLoveletters] = useState([]);
  const [selectedLoveletter, setSelectedLoveletter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggle = (section) => {
    setActiveSection(section);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (activeLetterSection === 'section1') {

      const getloveletters = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/loveletters/get`)

          setLoveletters((prevLoveletters) => [

            ...response.data
          ]);
        }
        catch (error) { console.log('There was an error getting the love letters!', error); }
      }

      getloveletters();
    }

  }, [activeLetterSection])


  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const postLoveLetter = async () => {

    console.log(name, message);

    try {
      if (name && message) {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/loveletters/post`, {
          name: name,
          message: message
        });
        console.log(response.data);
        setName('')
        setMessage('')
      }

    } catch (error) {
      console.error('There was an error posting the love letter!', error);
    }

  };


  const [newCategory, setNewCategory] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {

    const getcategories = async () => {
      try {
        const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getcategory`)

        // console.log(data.data);
        setCategories(data.data);
      }
      catch (error) { console.log('There was an error getting the love letters!', error); }
    }

    getcategories();

  }, [])

  const addCategory = async () => {
    try {
      if (newCategory) {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/addcategory`, {
          category: newCategory,
        });
        // console.log(response.data);
        setCategories((prev) => [...prev, response.data]) //push category to categoriesss
        setNewCategory('')
      }
    } catch (error) {
      console.error('There was an error posting the love letter!', error);
    }
  };



  // const [productCategory, setProductCategory] = useState('');
  const [newSubCategory, setNewSubCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [productSubCategory, setProductSubCategory] = useState('');

  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState('');

  useEffect(() => {
    const category = categories.filter(c => c.category === productCategory)
    // console.log(category)
    const subcategories = category[0]?.subcategories
    // console.log(subcategories)
    if (subcategories) {
      setSubCategories(subcategories)
    }
  }, [productCategory, categories])


  const addSubCategory = async () => {
    try {
      if (newSubCategory) {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/addsubcategory`, {
          category: productCategory,
          subcategory: newSubCategory,
        });
        const updatedCategory = response.data;

        setCategories((prev) =>
          prev.map((category) =>
            category._id === updatedCategory._id ? updatedCategory : category
          )
        );
        setNewSubCategory('')

      }
    } catch (error) {
      console.error('There was an error posting the love letter!', error);
    }
  };

  const [ringOptions, setRingOptions] = useState(""); // Stores "Yes" or "No" for the main question
  const [selectedOptions, setSelectedOptions] = useState([]); // Stores selected options

  const ringoptions = ['emerald', 'oval', 'round'];

  // Function to handle checkbox changes
  const handleCheckboxChange = (option) => {
    setSelectedOptions(prevState =>
      prevState.includes(option)
        ? prevState.filter(opt => opt !== option) // Remove if already selected
        : [...prevState, option] // Add if not selected
    );
  };

  const [halfBand, setHalfband] = useState(false)


  // Open the modal and disable scrolling on the body
  const handleViewClick = (loveletter) => {
    // console.log(loveletter)
    setSelectedLoveletter(loveletter);
    setIsModalOpen(true);
    document.body.classList.add('overflow-hidden');
  };

  // Close the modal and enable scrolling
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLoveletter(null);
    document.body.classList.remove('overflow-hidden');
  };

  // Close modal on click outside
  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      closeModal();
    }
  };

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [file3, setFile3] = useState();
  const [file4, setFile4] = useState();


  const [imageBoxCount, setImageBoxCount] = useState(1);

  const addimageboxes = () => {
    if (imageBoxCount < 4) {
      setImageBoxCount(imageBoxCount + 1);
    }

  };

  const [haloOptions, setHaloOptions] = useState(false)
  // console.log(haloOptions)


  const [customPrices, setcustomPrices] = useState([
    // { metal: '', price: '' },
    { metal: 'Silver', price: '' },
    { metal: '14kt', price: '' },
    { metal: '18kt', price: '' },
    { metal: '22kt', price: '' }
  ]);
  // useless state, will delete after everything ^^^^^^^^^^^^ok
  // yei neeche walei yr dikat hoki what she want bronze jewekkery
  // she can add a new row?


  const [customPricesRings, setcustomPricesRings] = useState({
    HalfBand: [
      { metal: 'Silver', price: '' },
      { metal: '14kt', price: '' },
      { metal: '18kt', price: '' },
      { metal: '22kt', price: '' }
    ],
    FullBand: [
      { metal: 'Silver', price: '' },
      { metal: '14kt', price: '' },
      { metal: '18kt', price: '' },
      { metal: '22kt', price: '' }
    ]
  });

  const [customPricesNo, setcustomPricesNo] = useState(
    {
      NoOption: [
        { metal: 'Silver', price: '' },
        { metal: '14kt', price: '' },
        { metal: '18kt', price: '' },
        { metal: '22kt', price: '' }
      ]
    }
  );

  const [customPricesEarrings, setcustomPricesEarrings] = useState({
    Halo: [
      { metal: 'Silver', price: '' },
      { metal: '14kt', price: '' },
      { metal: '18kt', price: '' },
      { metal: '22kt', price: '' }
    ],
    withoutHalo: [
      { metal: 'Silver', price: '' },
      { metal: '14kt', price: '' },
      { metal: '18kt', price: '' },
      { metal: '22kt', price: '' }
    ]
  });


  const handleFieldChange = (setData, bandType, index, field, value) => {
    setData(prevState => ({
      ...prevState,
      [bandType]: prevState[bandType].map((entry, i) =>
        i === index ? { ...entry, [field]: value } : entry
      )
    }));
  };

  const addEntry = (setData, bandType) => {
    setData(prevState => ({
      ...prevState,
      [bandType]: [...prevState[bandType], { metal: '', price: '' }]
    }));
  };

  const removeEntry = (setData, bandType, index) => {
    setData(prevState => ({
      ...prevState,
      [bandType]: prevState[bandType].filter((_, i) => i !== index)
    }));
  };


  useEffect(() => {
    // console.log(customPricesRings, 'customPrices')
  }, [customPricesRings])


  const [aboutProduct, setAboutProduct] = useState('');
  const [goldWeight, setgoldWeight] = useState('');
  const [diamondWeight, setdiamondWeight] = useState('');


  const submitProduct = async (e) => {
    e.preventDefault();


    const formData = new FormData();

    formData.append("image", file1);
    formData.append("image", file2);
    formData.append("image", file3);
    formData.append("image", file4);
    formData.append("category", productCategory);
    formData.append("subCategory", productSubCategory);
    formData.append("name", productName);
    formData.append("code", productCode);
    formData.append("about", aboutProduct);
    formData.append("goldWeight", goldWeight);
    formData.append("diamondWeight", diamondWeight);
    formData.append("Options", JSON.stringify(selectedOptions));
    if (productCategory === "Rings") {
      if (halfBand === 'Yes') {
        formData.append("CustomPrices", JSON.stringify(customPricesRings));
      } else {
        formData.append("CustomPrices", JSON.stringify(customPricesNo));
      }
    }

    if (productCategory === "Earrings" || productCategory === "Neckpieces") {
      if (haloOptions === 'Yes') {
        formData.append("CustomPrices", JSON.stringify(customPricesEarrings));
      } else {
        formData.append("CustomPrices", JSON.stringify(customPricesNo));
      }
    }

    if (productCategory === "Wristwear") {
      formData.append("CustomPrices", JSON.stringify(customPricesNo));
    }


    if (productCategory && productSubCategory && productName && productCode && goldWeight && diamondWeight) {
      // console.log(customPricesNo)
      try {
        const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/addproduct`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })


        if (data.data) {
          window.location.reload();
        }

      }
      catch (err) { console.log(err); }
    }

  };

  const DeleteLoveLetter = async () => {
    console.log(selectedLoveletter._id)
    const id = selectedLoveletter._id
    try {
      //  await axios.post('/deleteletter', { id });

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/loveletters/deleteletter`, {
        id
      });

      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error posting ID to deleteletter:', error);

    }

  }


  const lovelettersHTML = {
    section1: (
      <div className='flex flex-col gap-6 w-full'>

        <div className='relative'>
          <div className='font-subheading text-3xl mb-6'>add love letter</div>
          <div>YOUR NAME *</div>
          <input className='input formInput'
            type="text"
            placeholder='name'
            value={name}
            onChange={(e) => { setName(e.target.value) }}
          />

        </div>

        <div className='relative'>

          <div>YOUR MESSAGE *</div>
          <input className='input formInput'
            type="text"
            placeholder='message'
            value={message}
            onChange={(e) => { setMessage(e.target.value) }}
          />

        </div>

        <button className='mt-4' onClick={() => { postLoveLetter() }}>SUBMIT</button>

      </div>
    ),
    section2: (
      <div className="flex w-full">
      {/* Sidebar with vertical tabs */}
      <div className="w-full p-4 bg-gray-50 rounded-lg shadow-lg overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4 text-black">All Love Letters</h2>
        <ul>
          {loveletters.map((review) => (
            <li key={review._id} className="mb-2">
              <div className="flex justify-between items-center bg-white p-3 rounded-lg shadow-md hover:bg-pink-50">
                <span className="font-medium text-black">{review.name}</span>
                <button
                  onClick={() => handleViewClick(review)}
                  className="text-sm text-pink-600 underline hover:text-pink-800"
                >
                  View
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Modal for viewing review details */}
      {isModalOpen && selectedLoveletter &&
        (
          <div
            id="modal-overlay"
            className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50"
            onClick={handleOutsideClick}
          >
            <div className="bg-white w-3/4 md:w-1/2 lg:w-1/3 p-6 rounded-lg shadow-lg relative">
              {/* Close Button in Top Right Corner */}
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                &times; {/* Close icon */}
              </button>

              <h3 className="text-2xl text-black font-semibold mb-4">{selectedLoveletter.name}'s Review</h3>
              {isEditing ? (
                <textarea
                  value={editedMessage}
                  onChange={(e) => setEditedMessage(e.target.value)}
                  className="w-full h-24 p-2 border border-gray-300 rounded-lg mb-4"
                />
              ) : (
                <p className="text-black mb-6">{selectedLoveletter.message}</p>
              )}

              {/* Action buttons */}
              <div className="flex justify-between space-x-3">
                {isEditing ? (
                  <button
                    onClick={() => { handleSave() }}
                    className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={() => { handleEdit() }}
                    className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                  >
                    Edit
                  </button>
                )}
                <button
                  onClick={() => { DeleteLoveLetter() }}
                  className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                >
                  Delete
                </button>
                <button
                  onClick={() => alert('Archive function triggered')}
                  className="px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600"
                >
                  Archive
                </button>
              </div>
            </div>
          </div>
        )}
    </div>
    ),
  }

  console.log(productCategory)

  const content = {
    section1: (
      <div className='w-full max-w-[600px] text-left text-white'>

        <div className="flex space-x-2 mb-4">
          <button
            className={`py-2 px-4 rounded-lg ${activeLetterSection === 'section1' ? 'bg-pink-100 border border-pink-500' : 'hover:bg-pink-50'}`}
            onClick={() => setActiveLetterSection('section1')}
          >
            Add
          </button>
          <button
            className={`py-2 px-4 rounded-lg ${activeLetterSection === 'section2' ? 'bg-pink-100 border border-pink-500' : 'hover:bg-pink-50'}`}
            onClick={() => setActiveLetterSection('section2')}
          >
            Remove
          </button>
        </div>

        {lovelettersHTML[activeLetterSection]}

      </div>
    ),
    section2: (
      <div>
        <div className='font-subheading text-3xl mb-6'>add product Category</div>


        <div className='flex gap-[5%] w-full'>

          <div className='w-full'>
            <div>Add Product Category</div>
            <input className='input formInput'
              type="text"
              placeholder='category'
              value={newCategory}
              onChange={(e) => { setNewCategory(e.target.value) }}
            />
            <button onClick={addCategory}>Add Category</button>
          </div>

          <div className='w-full'>
            <div>Existing Categories</div>
            <select className='input formInput'
              value={productCategory}

            >
              <option value=''></option>
              {categories && categories.map(category =>
                <option value={category.category}>{category.category}</option>
              )}

            </select>
          </div>

        </div>
      </div>
    ),
    section3: (
      <div>
        <div className='font-subheading text-3xl mb-6'>add product Sub-Category</div>
        <div className=''>


          <div className='flex gap-[5%] w-full'>


            <div className='w-full'>
              <div>Select a Category</div>
              <select className='input formInput'
                value={productCategory}
                onChange={(e) => { setProductCategory(e.target.value) }}
              >
                <option value=''></option>
                {categories && categories.map(category =>
                  <option value={category.category}>{category.category}</option>
                )}

              </select>
            </div>

            <div className='w-full'>
              <div>Existing Sub-Categories</div>
              <select className='input formInput'
                value={productSubCategory}
                onChange={(e) => { setProductSubCategory(e.target.value) }}
              >
                <option value=''></option>
                {subCategories && subCategories.map(c =>
                  <option value={c}>{c}</option>
                )}

              </select>
            </div>
          </div>


          <div className='mt-4'>
            <div>Add Product Sub-Category</div>
            <input className='input formInput'
              type="text"
              placeholder='sub-category'
              value={newSubCategory}
              onChange={(e) => { setNewSubCategory(e.target.value) }}
            />
            <button onClick={addSubCategory}>Add Sub-Category</button>
          </div>

        </div>
      </div>
    ),
    section4: (<div>

      <div className='font-subheading text-3xl mb-6'>add a product </div>
      <div className='flex w-full flex-col !gap-8'>

        <div>
          <div>Select a Category</div>
          <select className='input formInput'
            value={productCategory}
            onChange={(e) => { setProductCategory(e.target.value) }}
          >
            <option value=''></option>
            {categories && categories.map(category =>
              <option value={category.category}>{category.category}</option>
            )}

          </select>
        </div>

        <div className='w-full'>
          <div>Select Product Sub-Category</div>
          <select className='input formInput'
            value={productSubCategory}
            onChange={(e) => { setProductSubCategory(e.target.value) }}
          >
            <option value=''></option>
            {subCategories && subCategories.map(c =>
              <option value={c}>{c}</option>
            )}

          </select>
        </div>




        {productSubCategory && <>
          <div className='formGrid mt-8'>

            <div className='w-full'>
              <div>Product Name</div>
              <input className='input formInput'
                type="text"
                value={productName}
                onChange={(e) => { setProductName(e.target.value) }}
              />
            </div>

            <div className='w-full'>
              <div>Product Code</div>
              <input className='input formInput'
                type="text"
                value={productCode}
                onChange={(e) => { setProductCode(e.target.value) }}
              />
            </div>

          </div>

          <div>

            <div>Add Images</div>

            <div className='formGrid'>

              <div>
                <input
                  onChange={
                    e => setFile1(e.target.files[0])
                  }
                  type="file"
                  accept="image/*"
                  className='input formInput'
                  name='image'
                />
              </div>

              {imageBoxCount > 1 ? <div>

                <input
                  onChange={
                    e => setFile2(e.target.files[0])
                  }
                  type="file"
                  accept="image/*"
                  className='input formInput'
                  name='image'
                />

              </div> : <></>}

              {imageBoxCount > 2 ? <div>

                <input
                  onChange={
                    e => setFile3(e.target.files[0])
                  }
                  type="file"
                  accept="image/*"
                  className='input formInput'
                  name='image'
                />

              </div> : <></>}

              {imageBoxCount > 3 ? <div>

                <input
                  onChange={
                    e => setFile4(e.target.files[0])
                  }
                  type="file"
                  accept="image/*"
                  className='input formInput'
                  name='image'
                />

              </div> : <></>}

            </div>

            <br />
            <button onClick={() => { addimageboxes() }}>add more images</button>

          </div>
          {/* use this state for radio checkbox */}
          {/* const[ringOptions,setRingOptions]=useState(false); */}

          {/* if someone clicks yes make  */}


          {/* ringoptions  ->map this array for options, when product is rings */}





          {(productCategory === 'Rings' || productCategory === 'Earrings') ?
            <div>
              {/* radio insert here, Does your product have any options?
            options-yes or no */}

              <div>Does your product have any options?</div>

              <div className='flex gap-4 justify-evenly items-center'>
                <div>
                  <input
                    type="radio"
                    name='ringOptions'
                    id='yes'
                    value="Yes"
                    onClick={(e) => setRingOptions(e.target.value)}
                    checked={ringOptions === "Yes"}
                  />
                  <label htmlFor="yes" className='ml-3 cursor-pointer'>Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name='ringOptions'
                    id='no'
                    value="No"
                    onClick={(e) => setRingOptions(e.target.value)}
                    checked={ringOptions === "No"}
                  />
                  <label htmlFor="no" className='ml-3 cursor-pointer'>No</label>
                </div>
              </div>

              {ringOptions === "Yes" && (
                <div>
                  <div>Select Product Options:</div>
                  {ringoptions.map(option => (
                    <div key={option} className="flex items-center">
                      <input
                        type="checkbox"
                        id={option}
                        value={option}
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                      />
                      <label htmlFor={option} className="ml-2 cursor-pointer">{option}</label>
                    </div>
                  ))}
                </div>
              )}
            </div >
            : <></>
          }


          {productCategory == 'Rings' ?
            <>
              <div>
                <div>Does this Ring offer Half-Band or Full-Band Option?</div>

                <div className='flex gap-4 justify-evenly items-center'>
                  <div>
                    <input
                      type="radio"
                      name='halfBand'
                      id='yes'
                      value="Yes"
                      onClick={(e) => setHalfband(e.target.value)}
                      checked={halfBand == "Yes"}
                    />
                    <label htmlFor="yes" className='ml-3 cursor-pointer'>Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name='halfBand'
                      id='no'
                      value="No"
                      onClick={(e) => setHalfband(e.target.value)}
                      checked={halfBand === "No"}
                    />
                    <label htmlFor="no" className='ml-3 cursor-pointer'>No</label>
                  </div>
                </div>


              </div >

              {halfBand === 'Yes' ? (
                <>
                  <div className='w-full'>
                    <div>Add Half-Band Metals & Prices</div>
                    {customPricesRings.HalfBand?.map((entry, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <input
                          type="text"
                          value={entry.metal}
                          onChange={(e) => handleFieldChange(setcustomPricesRings, 'HalfBand', index, 'metal', e.target.value)}
                          placeholder="Metal"
                          className="input formInput"
                        />
                        <input
                          type="text"
                          value={entry.price}
                          onChange={(e) => handleFieldChange(setcustomPricesRings, 'HalfBand', index, 'price', e.target.value)}
                          placeholder="Price"
                          className="input formInput"
                        />
                        <button onClick={() => removeEntry(setcustomPricesRings, 'HalfBand', index)} color="red">Remove</button>
                      </div>
                    ))}
                    <button onClick={() => addEntry(setcustomPricesRings, 'HalfBand')} color="green">+ Add Entry</button>
                  </div>

                  <div className='w-full'>
                    <div>Add Full-Band Metals & Prices</div>
                    {customPricesRings.FullBand?.map((entry, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <input
                          type="text"
                          value={entry.metal}
                          onChange={(e) => handleFieldChange(setcustomPricesRings, 'FullBand', index, 'metal', e.target.value)}
                          placeholder="Metal"
                          className="input formInput"
                        />
                        <input
                          type="text"
                          value={entry.price}
                          onChange={(e) => handleFieldChange(setcustomPricesRings, 'FullBand', index, 'price', e.target.value)}
                          placeholder="Price"
                          className="input formInput"
                        />
                        <button onClick={() => removeEntry(setcustomPricesRings, 'FullBand', index)} color="red">Remove</button>
                      </div>
                    ))}
                    <button onClick={() => addEntry(setcustomPricesRings, 'FullBand')} color="green">+ Add Entry</button>
                  </div>
                </>
              ) :

                <div className='w-full'>
                  <div>Add Ring Metals & Prices</div>
                  {customPricesNo.NoOption?.map((entry, index) => (
                    <div key={index} className="flex gap-2 mb-2">
                      <input
                        type="text"
                        value={entry.metal}
                        onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'metal', e.target.value)}
                        placeholder="Metal"
                        className="input formInput"
                      />
                      <input
                        type="text"
                        value={entry.price}
                        onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'price', e.target.value)}
                        placeholder="Price"
                        className="input formInput"
                      />
                      <button onClick={() => removeEntry(setcustomPricesNo, 'NoOption', index)} color="red">Remove</button>
                    </div>
                  ))}
                  <button onClick={() => addEntry(setcustomPricesNo, 'NoOption')} color="green">+ Add Entry</button>
                </div>
              }

            </>
            : <></>
          }



          {(productCategory === 'Earrings' || productCategory === 'Neckpieces') ?
            <>


              <div>Does the Product offer Halo/Non-halo Options?</div>

              <div className='flex gap-4 justify-evenly items-center'>
                <div>
                  <input
                    type="radio"
                    name='haloOptions'
                    id='yes'
                    value="Yes"
                    onClick={(e) => setHaloOptions(e.target.value)}
                    checked={haloOptions === "Yes"}
                  />
                  <label htmlFor="yes" className='ml-3 cursor-pointer'>Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name='haloOptions'
                    id='no'
                    value="No"
                    onClick={(e) => setHaloOptions(e.target.value)}
                    checked={haloOptions === "No"}
                  />
                  <label htmlFor="no" className='ml-3 cursor-pointer'>No</label>
                </div>
              </div>

              {haloOptions === 'Yes' ? (
                <>
                  <div className='w-full'>
                    <div>Add Halo Metals & Prices</div>
                    {customPricesEarrings.Halo?.map((entry, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <input
                          type="text"
                          value={entry.metal}
                          onChange={(e) => handleFieldChange(setcustomPricesEarrings, 'Halo', index, 'metal', e.target.value)}
                          placeholder="Metal"
                          className="input formInput"
                        />
                        <input
                          type="text"
                          value={entry.price}
                          onChange={(e) => handleFieldChange(setcustomPricesEarrings, 'Halo', index, 'price', e.target.value)}
                          placeholder="Price"
                          className="input formInput"
                        />
                        <button onClick={() => removeEntry(setcustomPricesEarrings, 'Halo', index)} color="red">Remove</button>
                      </div>
                    ))}
                    <button onClick={() => addEntry(setcustomPricesEarrings, 'Halo')} color="green">+ Add Entry</button>
                  </div>

                  <div className='w-full'>
                    <div>Add withoutHalo Metals & Prices</div>
                    {customPricesEarrings.withoutHalo?.map((entry, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <input
                          type="text"
                          value={entry.metal}
                          onChange={(e) => handleFieldChange(setcustomPricesEarrings, 'withoutHalo', index, 'metal', e.target.value)}
                          placeholder="Metal"
                          className="input formInput"
                        />
                        <input
                          type="text"
                          value={entry.price}
                          onChange={(e) => handleFieldChange(setcustomPricesEarrings, 'withoutHalo', index, 'price', e.target.value)}
                          placeholder="Price"
                          className="input formInput"
                        />
                        <button onClick={() => removeEntry(setcustomPricesEarrings, 'withoutHalo', index)} color="red">Remove</button>
                      </div>
                    ))}
                    <button onClick={() => addEntry(setcustomPricesEarrings, 'withoutHalo')} color="green">+ Add Entry</button>
                  </div>
                </>
              ) :

                <div className='w-full'>
                  <div>Add Metals & Prices</div>
                  {customPricesNo.NoOption?.map((entry, index) => (
                    <div key={index} className="flex gap-2 mb-2">
                      <input
                        type="text"
                        value={entry.metal}
                        onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'metal', e.target.value)}
                        placeholder="Metal"
                        className="input formInput"
                      />
                      <input
                        type="text"
                        value={entry.price}
                        onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'price', e.target.value)}
                        placeholder="Price"
                        className="input formInput"
                      />
                      <button onClick={() => removeEntry(setcustomPricesNo, 'NoOption', index)} color="red">Remove</button>
                    </div>
                  ))}
                  <button onClick={() => addEntry(setcustomPricesNo, 'NoOption')} color="green">+ Add Entry</button>
                </div>
              }
            </ >
            : <></>
          }



          {productCategory === 'Wristwear' &&
            <div className='w-full'>
              <div>Add Wristwear Metals & Prices</div>
              {customPricesNo.NoOption?.map((entry, index) => (
                <div key={index} className="flex gap-2 mb-2">
                  <input
                    type="text"
                    value={entry.metal}
                    onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'metal', e.target.value)}
                    placeholder="Metal"
                    className="input formInput"
                  />
                  <input
                    type="text"
                    value={entry.price}
                    onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'price', e.target.value)}
                    placeholder="Price"
                    className="input formInput"
                  />
                  <button onClick={() => removeEntry(setcustomPricesNo, 'NoOption', index)} color="red">Remove</button>
                </div>
              ))}
              <button onClick={() => addEntry(setcustomPricesNo, 'NoOption')} color="green">+ Add Entry</button>
            </div>
          }




          <div className='w-full'>
            <div>About the Product</div>
            <textarea className='input formInput'
              type="text"
              value={aboutProduct}
              onChange={(e) => { setAboutProduct(e.target.value) }}
            />
          </div>

          <div className='formGrid'>
            <div className='w-full'>
              <div>Product Breakdown - Estimated Gold Weight (g) :</div>
              <input className='input formInput'
                type="text"
                value={goldWeight}
                onChange={(e) => { setgoldWeight(e.target.value) }}
              />
            </div>
            <div className='w-full'>
              <div>Estimated Diamond Weight (cttw) :</div>
              <input className='input formInput'
                type="text"
                value={diamondWeight}
                onChange={(e) => { setdiamondWeight(e.target.value) }}
              />
            </div>
          </div>





          <button onClick={submitProduct}>Add product</button>
        </>}
      </div>
    </div >),
    section5: (<div>
      <AddEditProds />
    </div>)
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    // Logic to save the edited message can be added here
    // console.log('Saving edited message:', editedMessage);
    setSelectedLoveletter({ ...selectedLoveletter, message: editedMessage });
    setIsEditing(false);
  };

  return (
    <div className='px-0 py-8 pb-0 flex flex-col gap-2 max-h-full'>

      <Link to='/'><div className='font-heading text-[48px]'>DASHBOARD</div></Link>

      <div className="flex h-full">
        {/* Sidebar (20% width) */}
        <div className="w-1/5 bg-white p-4  ">
          <button
            className={`w-full py-2 px-4 rounded mb-2 flex justify-star ${activeSection === 'section1' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
            onClick={() => handleToggle('section1')}
          >
            Love Letter
          </button>
          <button
            className={`w-full py-2 px-4 rounded mb-2 text-left ${activeSection === 'section2' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
            onClick={() => handleToggle('section2')}
          >
            Add Product Category

          </button>
          <button
            className={`w-full py-2 px-4 rounded mb-2 ${activeSection === 'section3' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
            onClick={() => handleToggle('section3')}
          >
            add Sub-Category
          </button>
          <button
            className={`w-full py-2 px-4 rounded mb-2 ${activeSection === 'section4' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
            onClick={() => handleToggle('section4')}
          >
            Add Product
          </button>
          <button
            className={`w-full py-2 px-4 rounded mb-2 ${activeSection === 'section5' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
            onClick={() => handleToggle('section5')}
          >
            Edit/Delete Product
          </button>
        </div>

        {/* Main Content (80% width) */}
        <div className="w-4/5 p-6 border-[6px] overflow-y-scroll h-full max-h-[calc(100vh-116px)] min-h-[calc(100vh-116px)] text-white text-left" style={{ borderColor: 'rgb(252, 231, 243)' }}>
          {content[activeSection]}
        </div>
      </div>


    </div>
  )
}

export default AdmindashBoard





