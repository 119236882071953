import React, { useEffect } from 'react'
import './policies.css';

const Shipping = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="privacy-policy-container">
            <div className="policy-section">
                <h1>Shipping Policy</h1>
                <p className="policy-paragraph">
                    This Shipping Policy (“Policy”) governs the terms and conditions under which Moheera Jewels (“Company,” “we,” “us,” or “our”) handles the shipment and delivery of Products purchased through our website moheerajewels.com (“Platform”). This Policy applies to all categories of Products, including but not limited to jewelry made from lab-grown diamonds, and all other jewelry offered by the Company (collectively referred to as “Products”). By purchasing Products from our Platform, the customer (“you,” “your”) acknowledges and agrees to this Policy in its entirety.
                </p>
                <p className="policy-paragraph">
                    The Company is committed to ensuring that Products are shipped securely, timely, and in full accordance with the provisions laid out below. Please read this Policy carefully before making any purchase.
                </p>
            </div>

            <div className="policy-section">
                <h2>1. Shipping Destinations</h2>
                <p className="policy-paragraph">
                    The Company provides shipping to all major cities and towns across India, subject to the availability of reliable courier services. Delivery to remote areas or regions that are inaccessible to our shipping partners may result in extended shipping times or refusal to ship.
                </p>
                <p className="policy-paragraph">
                    In addition to domestic shipping within India, the Company also offers international shipping upon customer request. Orders requesting international shipping will be subject to additional shipping charges, customs duties, and taxes, which will be the responsibility of the customer. The Company will inform the customer of these costs prior to processing the order for international shipment. Shipping times for international orders may vary based on destination and customs clearance procedures.
                </p>
                <p className="policy-paragraph">
                    All orders will be shipped to the addresses provided by the customer. Customers are advised to verify their shipping details before placing the order, as the Company shall not be held liable for non-delivery due to incorrect or incomplete addresses.
                </p>
            </div>

            <div className="policy-section">
                <h2>2. Shipping Costs</h2>
                <p className="policy-paragraph">
                    The Company offers free shipping on all domestic orders within India, irrespective of the product’s size, weight, or total value. No minimum purchase amount is required for domestic shipping to qualify for free delivery.
                </p>
                <p className="policy-paragraph">
                    For international orders, applicable shipping charges will be based on the destination and will include any taxes, customs duties, or additional fees. These costs are the customer’s responsibility and will be communicated before the order is processed.
                </p>
            </div>

            <div className="policy-section">
                <h2>3. Processing Time and Delivery Timeframes</h2>
                <p className="policy-paragraph">
                    The Company aims to process all orders within 20 - 30 business days after payment confirmation. The processing time includes order verification, quality control, and packaging. Orders placed on weekends or public holidays will be processed on the next business day.
                </p>
                <p className="policy-paragraph">
                    The estimated delivery timeframes will vary depending on the destination which may either be within India or abroad. While the Company strives to ensure timely deliveries, the estimated timeframes are not guaranteed and may be subject to the following factors:
                </p>
                <ul className="policy-list">
                    <li>Distance between the Company’s warehouse and the delivery address.</li>
                    <li>Courier service availability and potential delays due to unforeseen circumstances (such as weather conditions, strikes, natural disasters).</li>
                    <li>Public holidays or regional restrictions.</li>
                </ul>
                <p className="policy-paragraph">
                    If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please allow additional days in transit for delivery. If there will be a significant delay in shipment of your order, we will contact you via email or telephone.
                </p>
            </div>

            <div className="policy-section">
                <h2>4. Packaging and Handling</h2>
                <p className="policy-paragraph">
                    The Company takes utmost care in packaging Products to ensure they arrive in perfect condition. Each Product is carefully inspected before being packaged using secure, tamper-evident materials.
                </p>
                <p className="policy-paragraph">
                    High-value Products, such as custom-made jewelry, may receive additional protective packaging to safeguard them from damage during transit.
                </p>
                <p className="policy-paragraph">
                    If the customer receives a package that appears to have been tampered with or damaged in transit, the customer must immediately notify the Company through its website or email by mentioning your order reference number and should refuse to accept the delivery. In addition to refusing the package, the customer is required to capture and provide evidence (such as photographs or video) of the condition of the package and the reason for refusal when contacting the Company. The Company will take appropriate measures to investigate and rectify such incidents.
                </p>
            </div>

            <div className="policy-section">
                <h2>5. Shipping Confirmation and Tracking Information</h2>
                <p className="policy-paragraph">
                    Once your order has been processed and shipped, the Company will send a shipping confirmation via email or SMS to the customer’s registered contact details. Please note that the Company only uses reliable shipping partners to transport your order so as to ensure that it is secure and reaches you safely.
                </p>
                <p className="policy-paragraph">
                    Customers will receive status updates for their order, once it has been dispatched. These updates will be sent via WhatsApp, SMS, and/or email, allowing the customer to stay informed about the delivery progress and expected arrival time of the Product. Customers are responsible for checking these communications for updates. In case of any issues or delays, customers should promptly contact the Company or the designated shipping carrier for assistance.
                </p>
            </div>

            <div className="policy-section">
                <h2>6. Delivery Confirmation and Authentication Requirement</h2>
                <p className="policy-paragraph">
                    For security purposes to ensure that the product reaches only the person listed on the shipping label, the Company requires that authentication be done upon delivery of all Products. Customers must provide a government-issued photo identification (such as a driver's license, Aadhaar card, or passport) to authenticate the delivery. This ensures that the Product is received by the customer or an authorized recipient only and is necessary to protect your order from theft or fraud. No deliveries will be left unattended at the delivery address.
                </p>
                <p className="policy-paragraph">
                    In the event that no authorized individual is available to authenticate for the delivery, the courier service will either:
                </p>
                <ul className="policy-list">
                    <li>Attempt redelivery at a later time or date, or</li>
                    <li>Leave a notification with instructions for the customer to collect the package from the courier’s nearest facility.</li>
                </ul>
                <p className="policy-paragraph">
                    The customer must promptly follow up with the courier service to ensure that the package is collected or delivered.
                </p>
                <p className="policy-paragraph">
                    Upon authentication and delivery, the risk of loss, damage, or theft of the Product passes to the customer. The Company is not liable for any issues arising after the successful delivery of the Product.
                </p>
            </div>

            <div className="policy-section">
                <h2>7. Inaccuracy of Address and Failed Deliveries</h2>
                <p className="policy-paragraph">
                    The customer is responsible for providing accurate and complete delivery details, including the delivery address, contact information, and any other necessary instructions. In the case of incomplete or unclear address details, the Company may contact the customer for verification to prevent failed deliveries. The Company shall not be liable for any delays, additional costs, or non-delivery resulting from incorrect or incomplete information provided by the customer.
                </p>
                <p className="policy-paragraph">
                    In the event that the courier is unable to deliver the Product due to reasons attributable to the customer (such as provision of incorrect address, inability to sign for the delivery), and the Product is returned to the Company, the following options will be available:
                </p>
                <ul className="policy-list">
                    <li>Reshipment: The Company will reship the Product to the customer, subject to additional shipping charges. These charges will be borne by the customer.</li>
                    <li>Refund and Cancellation: The customer may request a cancellation of the order, in which case the Company will issue a refund minus the original shipping costs and any applicable fees.</li>
                </ul>
                <p className="policy-paragraph">
                    Any such refund shall be subject to the terms outlined in the Company’s Refund, Cancellation, and Return Policy.
                </p>
            </div>

            <div className="policy-section">
                <h2>8. Loss or Damage During Transit</h2>
                <p className="policy-paragraph">
                    The Company ensures that all shipments are insured for their full value during transit until the point of delivery.
                </p>
                <p className="policy-paragraph">
                    Customers must notify the Company within 24 hours of receiving a damaged Product or becoming aware of a shipping-related issue, such as non-receipt of the package. The Company will take appropriate action to investigate and resolve the matter.
                </p>
                <p className="policy-paragraph">
                    In cases of confirmed damage or loss during transit, the Company will offer the following options to the customer:
                </p>
                <ul className="policy-list">
                    <li>Reshipment: The Company will reship the Product at no additional cost, subject to Product availability.</li>
                    <li>Refund: If the Product is unavailable for reshipment, the Company will issue a refund for the total amount paid by the customer, including any shipping charges.</li>
                </ul>
            </div>

            <div className="policy-section">
                <h2>9. Cancellation, Returns and Refunds</h2>
                <p className="policy-paragraph">
                    Please refer to our Refund, Cancellation, and Return Policy which provides detailed information about options and procedures for returning your order. In case of any further queries, you may reach out to us through our contact details mentioned in the confirmation email or in the Contact Us section.
                </p>
            </div>

            <div className="policy-section">
                <h2>10. Miscellaneous</h2>
                <p className="policy-paragraph">
                    The Company shall not be held liable for any failure or delay in the shipment or delivery of Products due to circumstances beyond its reasonable control, including but not limited to natural disasters, acts of God, war, strikes, pandemics, governmental restrictions, or any other events that constitute force majeure under Indian law.
                </p>
                <p className="policy-paragraph">
                    The Company reserves the right to amend, modify, or update this Shipping Policy at any time without prior notice. Any such changes will be effective from the date they are posted on the Company’s website. Customers are advised to review this Policy periodically to stay informed of any changes.
                </p>
            </div>
        </div>

    )
}

export default Shipping