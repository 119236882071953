import axios from 'axios'
import React from 'react'
import { useState, useEffect } from 'react'


const AddEditProds = () => {
    const [datafromDB, SetdatafromDB] = useState()
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allProducts, setAllProducts] = useState()
    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState('');
    const [imageBoxCount, setImageBoxCount] = useState(1);

    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [file3, setFile3] = useState();
    const [file4, setFile4] = useState();
    const [preview, setPreview] = useState(null);
    const [selectedProductID,setSelectedProductID]=useState()
    const addimageboxes = () => {
        if (imageBoxCount < 4) {
            setImageBoxCount(imageBoxCount + 1);
        }

    };

    

    const handleViewClick = (product) => {
        console.log(product)
        setSelectedProduct(product);
        setIsModalOpen(true);
        document.body.classList.add('overflow-hidden');
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProduct(null);
        document.body.classList.remove('overflow-hidden');
    };

    // Close modal on click outside
    const handleOutsideClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            closeModal();
        }
    };
    // 
    useEffect(() => {

        const getproducts = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)
                SetdatafromDB(data)
                // console.log(data.data);
                // setFilteredProducts(data.data);
                setAllProducts(data.data);
                console.log(data.data)
            }
            catch (error) { console.log('There was an error getting the products!', error); }
        }

        getproducts();

    }, [])


    // Step 1: State Variables for each field
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [code, setCode] = useState('');
    const [customPrices, setCustomPrices] = useState({});
    const [goldWeight, setGoldWeight] = useState('');
    const [diamondWeight, setDiamondWeight] = useState('');
    const [options, setOptions] = useState([]);

    // Load selectedProduct data into state
    useEffect(() => {
        if (selectedProduct) {
            setName(selectedProduct.name);
            setCategory(selectedProduct.category);
            setSubCategory(selectedProduct.subCategory);
            setCode(selectedProduct.code);
            setCustomPrices(selectedProduct.customPrices);
            setGoldWeight(selectedProduct.goldWeight);
            setDiamondWeight(selectedProduct.diamondWeight);
            setOptions(selectedProduct.options);
        }
    }, [selectedProduct]);

    // Step 2: Update nested customPrices data
    const handleCustomPriceChange = (e, type, index) => {
        const updatedPrice = e.target.value;

        // Update the selectedProduct state
        setSelectedProduct((prevProduct) => {
            const updatedCustomPrices = { ...prevProduct.customPrices };
            updatedCustomPrices[type][index].price = updatedPrice;

            return { ...prevProduct, customPrices: updatedCustomPrices };
        });
    };

    // Step 3: Handle Save Button - Axios Call
    const handleSave = async () => {
        const updatedProduct = {
            _id: selectedProduct._id,
            about: selectedProduct.about,
            imageURLs: selectedProduct.imageURLs,
            name,
            category,
            subCategory,
            code,
            customPrices,
            goldWeight,
            diamondWeight,
            options,
        };

        console.log(updatedProduct)
        try {
            const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/editproduct`, { updatedProduct });


            if (data.data) {
                closeModal();
            }

        }
        catch (err) { console.log(err); }
    };

    // State variables for the new image modal
    const [viewImgModalOpen, setViewImgModalOpen] = useState(false);
    const [selectedViewImgData, setSelectedViewImgData] = useState([]);
    const [newViewImgURL, setNewViewImgURL] = useState("");

    // Function to open the image modal
    const handleViewImgClick = (product) => {
        console.log()
        setSelectedProductID(product._id)
        const length = product.imageURLs.length
        setImageBoxCount(4 - length)
        setSelectedViewImgData(product.imageURLs || []);
        setViewImgModalOpen(true);
    };

    // Function to close the image modal
    const closeViewImgModal = () => {
        setViewImgModalOpen(false);
        setNewViewImgURL("");
    };

    // Function to delete an image from the list
    const handleViewImgDelete = async(index) => {
        const updatedImages = [...selectedViewImgData];
        updatedImages.splice(index, 1);
        console.log(updatedImages)


       
        try {
          //  await axios.post('/deleteletter', { id });
    
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/deleteimage`, {
            updatedImages,_id:selectedProductID
          });
    
          console.log('Response:', response.data);
        } catch (error) {
          console.error('Error posting ID to deleteletter:', error);
    
        }
        setSelectedViewImgData(updatedImages);
    };



    // Function to add a new image
    const handleAddViewImg = () => {
        if (newViewImgURL.trim()) {
            setSelectedViewImgData([
                ...selectedViewImgData,
                { url: newViewImgURL, fileName: newViewImgURL.split('/').pop() }
            ]);
            setNewViewImgURL("");
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = 'auto';
        }

        if (isModalOpen) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = 'auto';
        }


        // Cleanup function to reset the overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    useEffect(() => {
        if (viewImgModalOpen) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = 'auto';
        }

        if (viewImgModalOpen) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = 'auto';
        }


        // Cleanup function to reset the overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [viewImgModalOpen]);

    const uploadPhoto =async(e)=>{

        e.preventDefault();
console.log(selectedProductID)

        const formData = new FormData();
    
        formData.append("image", file1);
        formData.append("_id", selectedProductID);
        try {
            const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/addimage`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    
    
            // if (data.data) {
            //   window.location.reload();
            // }
    
          }
          catch (err) { console.log(err); }

    }

    return (
        <div className="flex w-full">
            {/* Sidebar with vertical tabs */}
            <div className="w-full p-4 bg-gray-50 rounded-lg shadow-lg overflow-y-auto">
                <h2 className="text-xl font-semibold mb-4 text-black">Add/Edit Products</h2>
                <ul>
                    {allProducts?.map((product) => (
                        <li key={product._id} className="mb-2">
                            <div className="grid grid-cols-3 gap-4 bg-white p-3 rounded-lg shadow-md hover:bg-pink-50">
                                <span className="font-medium text-black">{product.name}</span>
                                <button
                                    onClick={() => handleViewClick(product)}
                                    className="text-sm hover:underline hover:text-pink-800"
                                >
                                    View
                                </button>
                                <button
                                    onClick={() => handleViewImgClick(product)}
                                    className="text-sm hover:underline hover:text-pink-800"
                                >
                                    Edit Images
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Modal for viewing review details */}
            {isModalOpen && selectedProduct &&
                (
                    <div
                        id="modal-overlay"
                        className="fixed inset-0 p-6 bg-black bg-opacity-40 flex items-center justify-center max-h-[100vh] z-50"
                        onClick={handleOutsideClick}
                    >
                        <div className="bg-white w-3/4 md:w-1/2 lg:w-1/3 p-6 rounded-lg shadow-lg relative overflow-y-scroll max-h-[85vh] scroll-hide">

                            <button
                                onClick={closeModal}
                                className="absolute top-2 right-2 text-white hover:text-[var(--secondary)] text-xl flex justify-center items-center"
                            >
                                &times;
                            </button>

                            <h3 className="text-2xl text-black font-semibold mb-4">{selectedProduct.name}</h3>


                            <div className="flex flex-col space-y-3 p-4 border rounded-lg shadow-md bg-white">
                                {/* Product Name */}
                                {/* <h2 className="text-xl font-bold text-gray-800">{selectedProduct.name}</h2> */}

                                {/* Category and Subcategory */}
                                <p className="text-gray-600">
                                    <span className="font-semibold">Category:</span> {selectedProduct.category} - {selectedProduct.subCategory}
                                </p>

                                {/* Product Code */}
                                <p className="text-gray-600">
                                    <span className="font-semibold">Code:</span> {selectedProduct.code}
                                </p>

                                {/* Pricing Information */}
                                <div className="text-gray-600">
                                    {/* <span className="font-semibold">Pricing:</span>
                                    <ul className="space-y-1 mt-1">
                                        <li><span className="font-medium">Defaul Pricing</span></li>
                                        {selectedProduct?.customPrices?.NoOption?.map((option, index) => (
                                            <li key={index} className="text-sm">
                                                {option.metal}: ₹{option.price}
                                            </li>
                                        ))}
                                        <li><span className="font-medium">Half Band:</span></li>
                                        {selectedProduct?.customPrices?.HalfBand?.map((option, index) => (

                                            
                                            <li key={index} className="text-sm">
                                                {option.metal}: ₹{option.price}
                                            </li>
                                        ))}
                                        <li><span className="font-medium">Full Band:</span></li>
                                        {selectedProduct?.customPrices?.FullBand?.map((option, index) => (
                                            <li key={index} className="text-sm">
                                                {option.metal}: ₹{option.price}
                                            </li>
                                        ))}
                                        <li><span className="font-medium">Halo:</span></li>
                                        {selectedProduct?.customPrices?.Halo?.map((option, index) => (
                                            <li key={index} className="text-sm">
                                                {option.metal}: ₹{option.price}
                                            </li>
                                        ))}
                                        <li><span className="font-medium">Without Halo</span></li>
                                        {selectedProduct?.customPrices?.withoutHalo?.map((option, index) => (
                                            <li key={index} className="text-sm">
                                                {option.metal}: ₹{option.price}
                                            </li>
                                        ))}
                                    </ul> */}

                                    <span className="font-semibold">Pricing:</span>
                                    <ul className="space-y-1 mt-1">
                                        {/* Dynamically render pricing sections based on available keys */}
                                        {Object.entries(selectedProduct?.customPrices || {}).map(([type, prices]) => (
                                            <React.Fragment key={type}>
                                                <li className="font-medium mt-2">{type.replace(/([A-Z])/g, ' $1').trim()}:</li>
                                                {prices?.map((option, index) => (
                                                    <li key={index} className="text-sm flex items-center space-x-2 justify-between">
                                                        <span>{option.metal}:</span>
                                                        {/* Editable input for price */}
                                                        <input
                                                            type="text"
                                                            className="border rounded p-1 w-24"
                                                            value={option.price}
                                                            onChange={(e) => handleCustomPriceChange(e, type, index)}
                                                        />
                                                    </li>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </div>

                                {/* Weight Information */}
                                <p className="text-gray-600 flex justify-between items-center">
                                    <span className="font-semibold">Gold Weight:</span>
                                    <div>
                                        <input className='text-right'
                                            value={goldWeight}
                                            onChange={(e) => { setGoldWeight(e.target.value) }}
                                        />

                                        {/* {selectedProduct.goldWeight} */}
                                        g
                                    </div>
                                </p>
                                <p className="text-gray-600 flex justify-between items-center">
                                    <span className="font-semibold">Diamond Weight:</span>
                                    <div>
                                        <input className='text-right'
                                            value={diamondWeight}
                                            onChange={(e) => { setDiamondWeight(e.target.value) }}
                                        />
                                        {/* {selectedProduct.diamondWeight} */}
                                        ct
                                    </div>
                                </p>

                                {/* Available Options */}
                                <p className="text-gray-600 flex justify-between items-center">
                                    <span className="font-semibold">Available Shapes:</span> {selectedProduct.options.join(", ")}
                                </p>

                                {/* Action Buttons */}
                                <div className="flex justify-between space-x-3 mt-4">

                                    <button
                                        // onClick={() => DeleteLoveLetter()}
                                        className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        onClick={() => handleSave()}
                                        className="px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }


            {viewImgModalOpen && (
                <div
                    className="fixed top-0 inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50"
                    onClick={closeViewImgModal}
                >
                    <div
                        className="bg-white w-3/4 md:w-1/2 lg:w-1/3 p-6 rounded-lg shadow-lg overflow-y-auto max-h-[80vh] relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Close Button */}
                        <button
                            onClick={closeViewImgModal}
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        >
                            &times;
                        </button>

                        {/* Modal Heading */}
                        <h3 className="text-2xl text-black font-semibold mb-4">Edit Images</h3>

                        {/* Image List */}
                        <ul className="space-y-4">
                            {selectedViewImgData.map((image, index) => (
                                <li key={index} className="flex items-center justify-between bg-gray-100 p-3 rounded-lg shadow-sm">
                                    <img
                                        src={image.url}
                                        alt={`Image ${index + 1}`}
                                        className="w-20 h-20 object-cover rounded-lg"
                                    />
                                    <button
                                        onClick={() => handleViewImgDelete(index)}
                                        className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </li>
                            ))}
                        </ul>

                        {/* Add New Image */}
                        <div>

                            <div>Add Images</div>

                            <div className='formGrid'>

                                <div>
                                    <input
                                        onChange={
                                            e => setFile1(e.target.files[0])
                                        }
                                        type="file"
                                        accept="image/*"
                                        className='input formInput'
                                        name='image'
                                    />

                                    {file1?<button
                                    onClick={(e)=>uploadPhoto(e)}
                                    >upload</button>:<></>}
                                </div>

                               

                            </div>

                            <br />
                            {/* <button onClick={() => { addimageboxes() }}>add more images</button> */}

                        </div>
                    </div>
                </div>
            )}

        </div>

    )
}

export default AddEditProds