import React, { useState } from 'react'
import './collection.css'
import { useShoppingCart } from '../../context/ShoppingCartContext';
import img from '../../assets/product1.png';
import { Link, useLocation } from 'react-router-dom';

const Product = ({ item }) => {

    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart();

    const quantity = getItemQuantity(item._id);

    const [hover, setHover] = useState(false);
    const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);

    console.log(Object.values(item.customPrices)[0])

    const location = useLocation();


    return (
        <>
            <div className='min-h-[320px]'
                onMouseEnter={e => setHover(true)}
                onMouseLeave={e => setHover(false)}
            >
                <Link to={`/product/${item.code}`} >

                    <div className='ProductDiv'>

                        <div className={item.category == 'Rings' ? 'PD_imgDiv px-4 pt-3 pb-5' : 'PD_imgDiv'}>
                            <img src={item.imageURLs[0]?.url} />
                        </div>

                        <div className='PD_Content'>
                            <div className='text-[11px]'>
                                {item.code}
                            </div>

                            <div className='flex justify-between text-[12px] mt-4'>
                                <div className='pb-[2px]'>{item.name}</div>
                                <div className='flex justify-end gap-1'>
                                    {hover && Object.values(item.customPrices)[0].map((e, index) => (
                                        <div key={index} className='PD_carat'>{e.metal}</div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </Link>

                {location.pathname == '/' ?
                    <></>
                    :
                    (!hover ?
                        <div className='PD_desc'>
                            {item.about}
                        </div>
                        :

                        <div className='PD_actions'>

                            <div className='text-center'>INR {Object.values(item.customPrices)[0][0].price} Onwards</div>

                            {quantity === 0 ? (

                                <button
                                    onClick={() => { increaseCartQuantity(item._id); setShowAddedToCartModal(true) }}
                                    className='PD_actionBtn'
                                >
                                    + Add to Cart
                                </button>
                            ) :
                                <div className='flex sm:gap-8 items-center'>

                                    <div className='flex gap-0 justify-stretch'>

                                        <button
                                            onClick={() => decreaseCartQuantity(item._id)}
                                            className='bg-white text-[var(--secondary)] border border-white flex items-center text-lg rounded-none h-[30px]'
                                        >
                                            -
                                        </button>

                                        <div className='border border-white text-white px-4 rounded-none h-[30px]'>
                                            {quantity}
                                        </div>


                                        <button
                                            onClick={() => increaseCartQuantity(item._id)}
                                            className='bg-white text-[var(--secondary)] border border-white flex items-center text-lg rounded-none h-[30px]'
                                        >
                                            +
                                        </button>

                                    </div>

                                    {/* <div className=''> */}
                                    <button onClick={() => removeFromCart(item._id)}
                                        className='PD_actionBtn'>
                                        Delete
                                    </button>
                                    {/* </div> */}
                                </div>
                            }
                        </div>
                    )
                }
            </div>


            {/* {showAddedToCartModal ?
                <div className='AddedToCartModalContainer'>
                    <div className='AddedToCartModal'>
                        <div className='absolute top-[-12px] right-[6px] text-4xl flex items-center cursor-pointer'
                            onClick={() => setShowAddedToCartModal(false)}
                        >
                            ×
                        </div>

                        <div className='flex justify-center items-end gap-8'>
                            <div>
                                <div className='font-heading text-3xl uppercase'>{item.name}</div>
                                <div className='font-heading uppercase'>{item.code}</div>
                                <div className='font-subheading'>For INR {item.customPrices[0].price}</div>
                            </div>

                            <div className='modal_imgDiv'>
                                <img src={img} />
                            </div>
                        </div>

                        <div className='text-[var(--secondary)] mt-8'>ADDED TO CART!</div>
                    </div>
                </div>
                : null} */}

        </>
    )
}

export default Product