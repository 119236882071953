import React, { useEffect } from 'react'
import './policies.css';

const Terms = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="privacy-policy-container">
            <h1>Terms and Conditions</h1>
            <div className="policy-section">
                <p className="policy-paragraph">
                    Welcome to Moheera Jewels ("Company," "we," "us," or "our"). These Terms and Conditions ("Terms") govern your use of our website moheerajewels.com ("Platform"), including the activities of accessing, browsing, and purchasing our products. By utilizing the Platform, you are affirming your agreement to comply with and be bound by these Terms, as well as any related policies, including but not limited to:
                </p>
                <ul className="policy-list">
                    <li>Privacy Policy</li>
                    <li>Shipping Policy</li>
                    <li>Buy-Back Policy</li>
                    <li>Refund, Cancellation, and Return Policy</li>
                </ul>
                <p className="policy-paragraph">
                    It is of paramount importance to thoroughly read these Terms in their entirety, as they constitute the legally binding obligations that dictate your interactions and transactions with our services. Should you disagree with any aspect of these Terms, please refrain from using the Platform. We retain the right to revise and update these Terms at our discretion, and it is incumbent upon you to regularly review them in order to remain apprised of any changes.
                </p>
            </div>
            <div className="policy-section">
                <h2>1. Eligibility to Use the Platform</h2>
                <p className="policy-paragraph">
                    1.1 The Platform is accessible exclusively to individuals capable of forming legally binding contracts under applicable laws. By availing yourself of our services, you confirm that:
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            1.1.1 You are at least 18 years of age and legally competent to enter into a binding contractual arrangement.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            1.1.2 If an individual under the age of 18 seeks to use the Platform, such usage must occur solely under the supervision of a parent or legal guardian.
                        </p>
                    </li>
                </ul>
                <p className="policy-paragraph">
                    1.2 By accessing or utilizing the Platform, you represent and warrant that all registration information you submit is accurate, truthful, and complete, and you undertake to maintain the accuracy of this information. Failure to comply with these obligations may result in the suspension or termination of your account at our sole discretion.
                </p>
            </div>
            <div className="policy-section">
                <h2>2. Products and Pricing</h2>
                <p className="policy-paragraph">
                    2.1 All products displayed on the Platform are offered subject to availability. We reserve the right to modify, discontinue, or alter product offerings at any time, as well as to amend pricing without prior notification.
                </p>
                <p className="policy-paragraph">
                    2.2 Prices displayed on the Platform are exclusive of applicable taxes, unless expressly stated otherwise.
                </p>
                <p className="policy-paragraph">
                    2.3 We strive to provide accurate product descriptions and pricing information, but errors may occur. Should an error be identified in the pricing or description of a product that you have ordered, we reserve the right to cancel your order and provide a full refund.
                </p>
                <p className="policy-paragraph">
                    2.4 The images provided on the Platform are for illustrative purposes only, and there may be minor discrepancies between the depicted images and the actual products, including variations in color, texture, or appearance.
                </p>
                <p className="policy-paragraph">
                    2.5 The Company reserves the right to limit the quantity of products available for purchase per order or per customer.
                </p>
            </div>
            <div className="policy-section">
                <h2>3. Pricing Update</h2>
                <p className="policy-paragraph">
                    3.1 Our pricing is calculated using current precious metal, diamonds, and coloured stones prices to provide the best possible value.
                </p>
                <p className="policy-paragraph">
                    3.2 Prices may change due to fluctuations in the prices of precious metals, diamonds, and coloured stones. Pricing changes will reflect the metal rates on the day of purchase or advance payment.
                </p>
                <p className="policy-paragraph">
                    3.3 Variances between the estimated price on the website and the actual finished product may occur, and such changes will be collected or refunded to the customer at the time of final sale.
                </p>
            </div>
            <div className="policy-section">
                <h2>4. Orders, Payments, and Shipping</h2>
                <p className="policy-paragraph">
                    4.1 Order Placement: By placing an order through the Platform, you are making an offer to purchase the selected products pursuant to these Terms. Upon receipt of your order:
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            4.1.1 An acknowledgment email will be sent to you, which does not signify acceptance of your offer.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            4.1.2 All orders are subject to verification and acceptance by the Company.
                        </p>
                    </li>
                </ul>
                <p className="policy-paragraph">
                    4.2 We reserve the right, at our sole discretion, to refuse or cancel any order, including but not limited to situations where products are unavailable or there are inaccuracies in pricing or product details. If an order is canceled, we will inform you via email or phone using the contact details provided at the time of placing the order.
                </p>
                <p className="policy-paragraph">
                    4.3 Payment: We accept a variety of payment methods, including debit cards, Unified Payments Interface (UPI), and other secure payment gateways.
                </p>
                <p className="policy-paragraph">
                    4.4 For orders placed with the Company, a 50% deposit of the total product cost is required at the time of placing the order. The remaining 50% of the product cost will be payable when the order is ready for shipment.
                </p>
                <p className="policy-paragraph">
                    4.5 Payments are processed securely, and your payment information is handled in accordance with our Privacy Policy.
                </p>
                <p className="policy-paragraph">
                    4.6 Shipping: Our shipping procedures are elaborated in the Shipping Policy, which is accessible on our Platform. You are encouraged to review the Shipping Policy for details regarding processing time, estimated delivery timelines, and other pertinent terms.
                </p>
                <p className="policy-paragraph">
                    4.7 While the Company endeavors to ensure timely dispatch of products, we make no representations or guarantees regarding specific delivery dates and shall not be held liable for delays caused by factors beyond our control, including but not limited to issues with courier services, labor strikes, or natural disasters.
                </p>
            </div>
            <div className="policy-section">
                <h2>5. Taxes</h2>
                <p className="policy-paragraph">
                    Applicable taxes, including Goods and Services Tax (GST) of 3% on all products, will be added to the product prices as per prevailing laws. Taxes are calculated based on the applicable rates at the time of purchase. Any changes in tax regulations will be reflected accordingly in the pricing.
                </p>
            </div>

            <div className="policy-section">
                <h2>6. Refund, Cancellation, and Return</h2>
                <p className="policy-paragraph">
                    Customers are entitled to request cancellations, returns, and refunds in accordance with our Refund, Cancellation and Return Policy. Key points include:
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            6.1 Products that have been customized, engraved, or otherwise altered are generally non-returnable.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            6.2 Refunds will be processed to the original payment method used at the time of purchase.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            6.3 Processing times for refunds may vary depending on the financial institution involved.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            6.4 We reserve the right to deduct applicable fees from the refund amount, including restocking fees, administrative charges, and shipping costs.
                        </p>
                    </li>
                </ul>
                <p className="policy-paragraph">
                    For comprehensive information, please refer to the detailed Refund, Cancellation and Return Policy provided on our Platform.
                </p>
            </div>

            <div className="policy-section">
                <h2>7. Buy-Back</h2>
                <p className="policy-paragraph">
                    The Company may offer buy-back options for certain eligible products in accordance with our Buy-Back Policy. Eligibility for buy-back is subject to successful verification of the product's condition, authenticity, and compliance with original specifications.
                </p>
                <p className="policy-paragraph">
                    For further details on requirements, valuation methods, and terms applicable to the buy-back of lab-grown diamonds and jewelry, please refer to the Buy-Back Policy available on the Platform. The Company reserves the right to amend, modify, or discontinue the buy-back program at any time, without prior notice.
                </p>
            </div>

            <div className="policy-section">
                <h2>8. Intellectual Property</h2>
                <p className="policy-paragraph">
                    All content accessible on the Platform, including but not limited to (a) Textual content, (b) Images, (c) Graphics, (d) Logos, (e) Videos, and (f) Software are owned by the Company or its licensors and are protected under applicable intellectual property laws. Unauthorized copying, distribution, modification, or use of Platform content for commercial purposes is strictly prohibited and may result in legal action. Users are granted a limited, revocable, non-exclusive license to access and use the Platform for personal, non-commercial purposes, provided such use is in compliance with these Terms.
                </p>
            </div>

            <div className="policy-section">
                <h2>9. Prohibited Activities</h2>
                <p className="policy-paragraph">
                    9.1 While using the Platform, you agree not to engage in any of the following activities:
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            9.1.1 Attempting to gain unauthorized access to the Platform, accounts, or servers through hacking or other unlawful means.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            9.1.2 Transmitting viruses, malware, or any other software designed to disrupt, damage, or interfere with the proper functioning of the Platform.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            9.1.3 Using the Platform for unlawful activities, including fraudulent schemes, impersonating other individuals, or engaging in activities that violate intellectual property rights.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            9.1.4 Undertaking actions that inhibit other users' enjoyment of the Platform or create risk, liability, or harm to the Company or its users.
                        </p>
                    </li>
                </ul>
                <p className="policy-paragraph">
                    9.2 The Company reserves the right to take appropriate legal action, including:
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            9.2.1 Suspending or terminating your account.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            9.2.2 Referring incidents to the relevant law enforcement authorities.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            9.2.3 Seeking injunctive relief and pursuing claims for damages, as deemed appropriate under applicable laws.
                        </p>
                    </li>
                </ul>
            </div>

            <div className="policy-section">
                <h2>10. Accuracy of Billing and Account Information</h2>
                <p className="policy-paragraph">
                    10.1 We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order.
                </p>
                <p className="policy-paragraph">
                    10.2 These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address.
                </p>
                <p className="policy-paragraph">
                    In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the email and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
                </p>
            </div>

            <div className="policy-section">
                <h2>11. Use of Website and License</h2>
                <p className="policy-paragraph">
                    The Platform and its content are intended solely for personal and non-commercial use by visitors and online shoppers. Any use of the Platform or its content other than for personal and non-commercial purposes is prohibited. Prohibited commercial uses include, but are not limited to, resale of products, reproduction of content for commercial gain, and use of content for promotional or advertising purposes. You agree not to reproduce, publish, transmit, distribute, modify, create derivative works from, or commercially exploit the content of the Platform in any way.
                </p>
                <p className="policy-paragraph">
                    You further agree not to access or use the Platform in any manner that could damage, disable, overburden, or impair any of the Platform's services, or interfere with any other party's use and enjoyment of the Platform.
                </p>
            </div>

            <div className="policy-section">
                <h2>12. Product Description</h2>
                <p className="policy-paragraph">
                    We make all reasonable efforts to display the products listed for sale on our Platform as accurately as possible. However, the colors we use, as well as the display and color capabilities of your computer monitor, may affect the colors that you actually see on your screen. We cannot guarantee that your monitor's display of any product color, texture, or detail will be accurate.
                </p>
                <p className="policy-paragraph">
                    In addition, we do not warrant that product descriptions or other content are accurate, complete, reliable, current, or error-free. If a product offered on our Platform is not as described, your sole remedy is to return it in unused condition.
                </p>
            </div>

            <div className="policy-section">
                <h2>13. Disclaimer of Warranties</h2>
                <p className="policy-paragraph">
                    The Platform, its content, and all products are provided strictly "as is," without warranties of any kind, either express or implied, including but not limited to any kind of implied warranties of merchantability, fitness for a particular purpose and non-infringement.
                </p>
                <p className="policy-paragraph">
                    While we make reasonable efforts to ensure that the Platform is free of errors and interruptions, we do not guarantee that your use will be uninterrupted, secure, or error-free. You acknowledge and accept that your use of the Platform is entirely at your own risk.
                </p>
                <p className="policy-paragraph">
                    You agree that from time to time we may remove the Service for indefinite periods or cancel the Service at any time, without notice to you.
                </p>
            </div>

            <div className="policy-section">
                <h2>14. Limitation of Liability</h2>
                <p className="policy-paragraph">
                    To the fullest extent permissible under applicable law, the Company, along with its officers, employees, agents, and affiliates, shall not be held liable for any damages, whether direct, indirect, incidental, special, consequential, or punitive, arising out of or related to your use or inability to use the Platform. This includes but is not limited to damages resulting from any errors or omissions, service interruptions, data loss, unauthorized access and security breaches.
                </p>
                <p className="policy-paragraph">
                    In jurisdictions where limitations of liability are not permitted, the Company's liability shall be limited to the greatest extent permissible under applicable law.
                </p>
            </div>

            <div className="policy-section">
                <h2>15. Indemnification</h2>
                <p className="policy-paragraph">
                    You agree to indemnify, defend, and hold harmless the Company, its officers, directors, employees, agents, and affiliates from any claims, liabilities, damages, costs, or expenses (including reasonable legal fees) arising from:
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            15.1 Your use of the Platform.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            15.2 Your breach of these Terms.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            15.3 Your violation of any third-party rights.
                        </p>
                    </li>
                </ul>
                <p className="policy-paragraph">
                    This indemnification obligation extends to all forms of misuse of the Platform, including unauthorized access to your account due to negligence or failure to maintain adequate security.
                </p>
            </div>

            <div className="policy-section">
                <h2>16. Data Use</h2>
                <p className="policy-paragraph">
                    We collect and use personal information from customers to fulfill orders, provide customer service, and enhance the functionality of our Platform. Additionally, customer data may be used for marketing and promotional purposes, such as sending newsletters or promotional emails. Customers can opt out of these communications at any time by unsubscribing or contacting our customer service team.
                </p>
                <p className="policy-paragraph">
                    We share customer data with third-party service providers, such as shipping carriers and payment processors, to facilitate the delivery of orders and other business operations. These service providers are required to use customer data only for contractual obligations and to implement adequate security measures to protect customer information.
                </p>
                <p className="policy-paragraph">
                    For more details on how we collect, process, and protect customer data, including the rights customers have over their personal information, please refer to our Privacy Policy, which forms an integral part of this Policy and governs all aspects of data usage and protection.
                </p>
            </div>

            <div className="policy-section">
                <h2>17. Governing Law and Jurisdiction</h2>
                <p className="policy-paragraph">
                    These Terms will be governed by and construed in accordance with the laws of the jurisdiction in which the Company operates, without regard to its conflict of law principles. Any dispute arising out of or in connection with these Terms or the use of the Platform shall be subject to the exclusive jurisdiction of the courts located in that jurisdiction.
                </p>
            </div>

            <div className="policy-section">
                <h2>18. Miscellaneous</h2>
                <p className="policy-paragraph">
                    The Company reserves the right to modify, amend, or update these Terms at any time and without prior notice. Modifications will be effective immediately upon publication on the Platform. Your continued use of the Platform after such modifications constitutes your acknowledgment and acceptance of the updated Terms. It is your responsibility to review these Terms periodically to ensure that you are aware of any amendments or changes.
                </p>
                <p className="policy-paragraph">
                    If any provision of these Terms is found to be unlawful, void, or unenforceable for any reason, that provision shall be deemed severable from the remaining provisions and shall not affect their validity and enforceability.
                </p>
                <p className="policy-paragraph">
                    These Terms, together with any policies or operating rules posted by the Company on the Platform, constitute the entire agreement between you and the Company regarding your use of the Platform and supersede any prior agreements or understandings, whether written or oral, between you and the Company.
                </p>
            </div>

            <div className="policy-section">
                {/* <h2>Contact Information</h2> */}
                <p className="policy-paragraph">
                    If you have any questions, concerns, or inquiries regarding these Terms and Conditions, please contact us at:
                </p>
                <p className="policy-paragraph">
                    Moheera Jewels<br />
                    +918978785151<br />
                    moheerajewels@gmail.com
                </p>
                <p className="policy-paragraph">
                    We are committed to addressing your concerns and ensuring your satisfaction.
                </p>
            </div>

        </div>

    )
}

export default Terms