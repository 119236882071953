import React, { useEffect } from 'react'
import './policies.css';

const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>
            <div className="policy-section">
                <p className="policy-paragraph">
                    This Privacy Policy ("Policy") articulates how Moheera Jewels ("Company," "we," "us," or "our") gathers, processes, and discloses your Personal Information when you visit or purchase from our website moheerajewels.com ("Platform").
                </p>
                <p className="policy-paragraph">
                    This Policy pertains to all products and services provided by the Company, encompassing our website and other online services. By accessing or utilizing our Platform, you acknowledge and agree to the practices described in this Policy, and you recognize that all interactions with our Platform are subject to the terms set forth herein.
                </p>
                <p className="policy-paragraph">
                    The Company is committed to maintaining the confidentiality and security of your personal data. We prioritize transparency and strive to inform you fully about how your personal data is collected, used, and safeguarded when interacting with our Platform.
                </p>
                <p className="policy-paragraph">
                    This Policy aims to provide users with a comprehensive understanding of their data rights and the measures we employ to protect personal information, thereby fostering trust and ensuring compliance with applicable laws and regulations.
                </p>
            </div>
            <div className="policy-section">
                <h2>1. Collecting Personal Information</h2>
                <p className="policy-paragraph">
                    We collect a range of information from users, including both personal and non-personal data, to enhance user experience and provide our services effectively. Personal information refers to details that can identify you directly, such as your name, email address, phone number, and billing information. In contrast, non-personal information includes data that does not identify an individual directly, such as browser type, device characteristics, and general usage metrics. This distinction enables us to offer customized services while safeguarding user privacy for non-identifiable activities. In this Policy, any data regarding an identifiable individual is referred to as "Personal Information."
                </p>
                <p className="policy-paragraph">
                    The collection of information occurs through various means, such as when you visit our Platform, register an account, place an order, or engage with customer support. This information is vital for improving the functionality of our services, understanding user preferences, and ensuring that our Platform operates smoothly. We employ advanced tools and analytics to ensure that the data we gather is processed in a manner that provides optimal performance and tailored experiences for each user.
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            <strong>Device Information:</strong> We collect information about your device to facilitate the efficient operation of our Platform. This data enables us to enhance user experience by optimizing page load speeds, retaining your preferences, and personalizing the settings to improve your interaction with our Platform.
                        </p>
                        <p className="policy-bullet">
                            The information collected may include details such as your web browser version, IP address, time zone, and cookie data. This data is automatically gathered through cookies, log files, web beacons, tags, or pixels and serves to improve the Platform's functionality and analytics to optimize our services.
                        </p>
                        <p className="policy-bullet">
                            We may share this data with trusted partners, including analytics firms, and security providers, to analyze site performance and enhance overall service delivery. The use of device information also aids in ensuring secure interactions by detecting and preventing fraudulent activities, thus protecting both the user and the Platform. By continuously monitoring device data, we can identify suspicious behavior patterns that may indicate security risks, allowing us to take timely action to mitigate such threats. This proactive approach to security underscores our commitment to maintaining a safe and trusted environment for all users.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            <strong>Order Information:</strong> To fulfill orders placed via our Platform, we collect personal details such as your name, billing address, shipping address, email address, phone number, and payment information. This information is crucial for processing transactions, arranging shipment, providing order confirmations, and communicating with you effectively. Additionally, it is shared with third-party service providers, including logistics partners and payment processors, to fulfill contractual obligations.
                        </p>
                        <p className="policy-bullet">
                            The collection and use of order information extend beyond simply fulfilling transactions. We use this data to gain insights into customer preferences and trends, which enables us to enhance our product offerings and customer experience. By analyzing order history and patterns, we can better anticipate customer needs, personalize recommendations, and provide timely updates about products or services that may be of interest. This level of personalization aims to improve customer satisfaction and foster long-term relationships with our users.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            <strong>Customer Support Information:</strong> When you contact us for customer support, we may collect additional information necessary to assist you, including order specifics and contact details. This information ensures seamless support and may be shared with third-party providers involved in order processing, such as logistics and customer service partners. Our customer support team utilizes this information to resolve issues efficiently and to provide prompt responses to inquiries.
                        </p>
                        <p className="policy-bullet">
                            Customer support information also allows us to identify recurring issues and areas where our services may require improvement. By systematically analyzing support interactions, we can develop better solutions, update our policies, and implement preventative measures that enhance the overall user experience. This commitment to continuous improvement ensures that our services evolve in line with customer expectations and industry standards.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="policy-section">
                <h2>2. How We Use Collected Information</h2>
                <p className="policy-paragraph">
                    The Company uses your Personal Information for the following purposes:
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            <strong>Facilitating Transactions:</strong> To process payments, manage orders, and generate invoices. Accurate data ensures timely fulfillment of orders and efficient communication throughout the transaction process. By securely processing payment details and managing sensitive financial data, we prioritize both user convenience and security.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            <strong>To Provide Customer Support:</strong> Your contact details and information regarding previous transactions are used to address inquiries and deliver tailored support. This personalized approach enables our support team to provide context-specific assistance, thereby enhancing user satisfaction.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            <strong>To Improve User Experience:</strong> Non-personal data is analyzed to understand user preferences and enhance Platform performance, ensuring that our services align with user needs. This analysis includes assessing browsing patterns, tracking feature usage, and testing new functionalities, which collectively contribute to an improved and intuitive user experience.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            <strong>To Communicate with You:</strong> We use your email address to provide order-related information, promotional offers, and updates that may be of interest. Users may opt out of promotional communications by following the instructions provided in our messages. Our communication strategy aims to keep users informed while respecting their preferences regarding the frequency and type of information they receive.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="policy-section">
                <h2>3. Sharing Personal Information</h2>
                <p className="policy-paragraph">
                    We do not sell, lease, or trade your Personal Information to third parties. If you wish to verify which third parties your information is shared with, please contact us using the details provided in the 'Contact Information' section below. We may share aggregated demographic information—stripped of personal identifiers—with business partners, trusted affiliates, and advertisers for analytical purposes.
                </p>
                <p className="policy-paragraph">
                    We also share your Personal Information in the following circumstances:
                </p>
                <ul className="policy-list">
                    <li>
                        <p className="policy-bullet">
                            <strong>Service Providers:</strong> We disclose Personal Information to third-party service providers who assist in Platform operations and customer transactions, including payment processors, logistics providers, and customer service partners.
                        </p>
                    </li>
                    <li>
                        <p className="policy-bullet">
                            <strong>Compliance with Legal Obligations:</strong> We may disclose Personal Information when required by law, in response to lawful requests, such as subpoenas, court orders, or governmental regulations. We are dedicated to protecting our legal rights and complying with our obligations under the law. In such instances, we limit the scope of disclosure to only what is legally required and take steps to notify affected users when permitted by law.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="policy-section">
                <h2>4. Cookies</h2>
                <p className="policy-paragraph">
                    Cookies are employed on our Platform to improve user experience by retaining information such as login credentials and region settings. Cookies enable the Platform to remember your actions and preferences, thereby offering a seamless experience. We use different types of cookies, including functional, performance, and marketing cookies, each serving a specific purpose to enhance your interaction with our services.
                </p>
                <p className="policy-paragraph">
                    Functional cookies are necessary for basic operations, such as maintaining session state and facilitating navigation. Performance cookies help us understand user interactions, providing insights that drive improvements in Platform functionality. Marketing cookies, on the other hand, enable us to deliver tailored advertisements and promotional content based on user interests.
                </p>
                <p className="policy-paragraph">
                    Users can control the use of cookies through their browser settings, typically accessible via the “Tools” or “Preferences” menu. Please note that disabling cookies may impair certain functionalities of the Platform, thereby affecting user experience. Users are encouraged to review their cookie preferences periodically to manage privacy settings effectively.
                </p>
            </div>
            <div className="policy-section">
                <h2>5. Retention of Personal Information</h2>
                <p className="policy-paragraph">
                    We retain your Personal Information for as long as it is necessary to fulfill the purposes for which it was collected. For instance, we retain order information for our records until you request its deletion, subject to legal permissions. This ensures that we can fulfill our contractual obligations, provide effective customer support, and comply with statutory requirements.
                </p>
                <p className="policy-paragraph">
                    Data retention periods vary based on the type of information and the purpose of processing. Personal Information related to financial transactions is retained in accordance with accounting and taxation requirements, while non-personal data used for analytical purposes may be retained longer to identify trends and improve service quality. We periodically review retention policies, typically on an annual basis, to ensure compliance with current regulations and best practices. These reviews help us maintain a high standard of data protection and align with evolving legal requirements.
                </p>
            </div>
            <div className="policy-section">
                <h2>6. Changes to This Privacy Policy</h2>
                <p className="policy-paragraph">
                    The Company may modify this Policy from time to time to reflect changes in our practices, legal obligations, or operational requirements. When significant changes occur, we will update the "Last Updated" date at the bottom of this page and may also notify you via email or through a prominent notice on our Platform. Your continued use of the Platform after such changes indicates your acceptance of the revised Policy.
                </p>
                <p className="policy-paragraph">
                    We encourage users to review this Policy periodically to stay informed about how we protect your information. Any substantial changes that may impact your rights will be clearly communicated to ensure that you remain informed and in control of your personal data.
                </p>
            </div>
            <div className="policy-section">
                {/* <h2>7. Contact Us</h2> */}
                <p className="policy-paragraph">
                    Should you have any questions regarding this Privacy Policy or wish to exercise your rights, please contact us at:
                </p>
                <p className="policy-paragraph">
                    Moheera Jewels<br />
                    +918978785151<br />
                    moheerajewels@gmail.com
                </p>
                <p className="policy-paragraph">
                    We are committed to addressing any concerns you may have regarding privacy and the protection of your personal data. Our goal is to foster an open dialogue about data privacy and to ensure that all inquiries are resolved in a timely and satisfactory manner.
                </p>
            </div>
            <p className="policy-paragraph">
                Last Updated: 24/10/2024
            </p>
        </div>


    )
}

export default Privacy