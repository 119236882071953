import React from 'react'
import aboutImg from '../../../assets/about/homeabout.png'
import './About.css'
import { Link } from 'react-router-dom'


const About = () => {
    return (
        <div className='About'>

            <div className='homeAboutContent'>
                <div className='font-heading aboutText text-center'>
                As a brand, we want to be continually relevant to the modern woman and man
                </div>

                <div className='font-body aboutSubText text-center'>
                With increasing access to lab diamonds, it is easier for one to be dynamic and versatile with their accessories - but with the right jeweller.
                </div>

                <Link to='/ourstory'>
                    <button className='text-sm'>
                        <span>KNOW MORE ABOUT US</span>
                    </button>
                </Link>
            </div>

            <div className='HomeAboutImgDiv'>
                <img src={aboutImg} />
            </div>

        </div>
    )
}

export default About