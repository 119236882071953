import React, { useEffect } from 'react'
import './policies.css';

const Return = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="privacy-policy-container">
            <div className="policy-section">
                <h1>Refund, Cancellation, and Return Policy</h1>
                <p className="policy-paragraph">
                    This Refund, Cancellation, and Return Policy ("Policy") governs the terms and conditions under which customers of Moheera Jewels ("Company," "we," "us," or "our") may return or seek refunds for products purchased from our website moheerajewels.com. This Policy applies to all categories of Products offered by the Company, including, but not limited to, jewelry made from lab-grown diamonds, and all other jewelry offered by us (hereinafter collectively referred to as "Products").
                </p>
                <p className="policy-paragraph">
                    This Policy is binding upon both the customer and the Company and is intended to mitigate the Company’s liability while ensuring a transparent and equitable process for our customers. By purchasing any Product from our website, the customer acknowledges that they have read, understood, and agreed to this Policy in its entirety.
                </p>
            </div>

            <div className="policy-section">
                <h2>1. General Principles of Return and Refund</h2>
                <h3>1.1 Scope of Returns</h3>
                <p className="policy-paragraph">
                    The Company permits the return of Products under the specific conditions detailed in this Policy, provided all eligibility requirements are met. Failure to meet these requirements will result in the denial of the return request. Exceptions are only made if explicitly stated by the Company.
                </p>

                <h3>1.2 Final Sale Items</h3>
                <p className="policy-paragraph">
                    The following items are sold on a final sale basis and are not eligible for returns or refunds:
                </p>
                <ul className="policy-list">
                    <li>Customized, bespoke, or personalized jewelry, including items made to order based on the customer's specifications.</li>
                    <li>Jewelry that has been resized or altered, unless such resizing or alteration was performed by the Company and the item is subsequently found to be defective.</li>
                    <li>Items that have been engraved or modified at the customer’s request.</li>
                    <li>Products shipped internationally are not eligible for return or refund under any circumstances, except in cases of verified Manufacturing Defects.</li>
                </ul>

                <h3>1.3 Interpretation</h3>
                <p className="policy-paragraph">
                    The interpretation of this Policy, including whether a Product qualifies for return or refund, lies solely with the Company, whose decision shall be considered final and binding.
                </p>
            </div>

            <div className="policy-section">
                <h2>2. Conditions for Return Eligibility</h2>
                <h3>2.1 Timeframe for Return Requests</h3>
                <p className="policy-paragraph">
                    All return requests must be initiated within 3 days of the date of delivery to the customer. Requests made beyond this timeframe will not be entertained, and the Product will be considered final sale.
                </p>

                <h3>2.2 Product Condition</h3>
                <p className="policy-paragraph">
                    To be eligible for return, the Product must meet the following conditions:
                </p>
                <ul className="policy-list">
                    <li>The Product must be in an unused, unaltered, and undamaged condition, identical to the state in which it was delivered to the customer.</li>
                    <li>All original packaging, tags, certificates of authenticity, grading reports, and any other documentation provided with the Product must be returned intact.</li>
                    <li>Products exhibiting signs of wear, damage, tampering, resizing, engraving, or any other modification not performed by the Company will not be accepted for return.</li>
                </ul>

                <h3>2.3 Inspection Process</h3>
                <p className="policy-paragraph">
                    The Company will take additional steps during the inspection process to ensure that any product returned complies strictly with the terms of this Policy. Such inspections may include comprehensive assessments by experts to verify authenticity, quality, and compliance with the terms set forth by the Company.
                </p>
            </div>

            <div className="policy-section">
                <h2>3. Cancellation Policy</h2>
                <h3>3.1 Customer Cancellation</h3>
                <p className="policy-paragraph">
                    Customers may request cancellation of an order prior to the shipment of the Product. Once the Product has shipped, cancellation requests will not be accepted, and the order will be subject to the terms of this Policy.
                </p>
                <ul className="policy-list">
                    <li>To cancel an order, the customer must initiate the cancellation process on the website or communicate their intent via email within 24 hours of placing the order or before shipment, whichever is earlier.</li>
                    <li>In the event of an eligible cancellation, the Company will refund the full amount paid by the customer, minus any applicable cancellation or processing fees.</li>
                    <li>Orders for custom-made or personalized Products, including custom-designed jewelry or engraved items, may not be eligible for cancellation once production has commenced.</li>
                </ul>

                <h3>3.2 Company-Initiated Cancellation</h3>
                <p className="policy-paragraph">
                    The Company reserves the right to cancel any order at its sole discretion in circumstances such as:
                </p>
                <ul className="policy-list">
                    <li>The Product is out of stock or discontinued.</li>
                    <li>Errors in pricing or listing are identified.</li>
                    <li>Incomplete or inaccurate delivery information is provided by the customer.</li>
                    <li>Fraudulent or suspicious activity is detected.</li>
                </ul>
            </div>

            <div className="policy-section">
                <h2>4. Refunds for Cancelled Orders</h2>
                <p className="policy-paragraph">
                    Refunds for cancelled orders will be processed in accordance with this Policy. Refunds will be issued to the original payment method within the specified timeframe. Depending on the method of payment, additional processing time may be required by the customer’s financial institution.
                </p>
            </div>

            <div className="policy-section">
                <h2>5. Return Process</h2>
                <p className="policy-paragraph">
                    To initiate a return, the customer must submit a formal request through our designated customer support email or return portal. The request must include:
                </p>
                <ul className="policy-list">
                    <li>Proof of purchase (order number, invoice, or receipt).</li>
                    <li>A detailed explanation of the reason for the return.</li>
                    <li>Photographic evidence of the Product’s condition, if applicable.</li>
                </ul>

                <p className="policy-paragraph">
                    Upon receipt of the return request, the Company will review the submission to determine whether the Product qualifies for return. The Company may request additional information or evidence before making a final decision. Approval or denial of the return request will be communicated within 7 business days.
                </p>
            </div>

            <div className="policy-section">
                <h2>6. Refund Policy</h2>
                <p className="policy-paragraph">
                    If a return is approved after inspection, the refund will be processed within 7 business days. Refunds will be credited to the original payment method used at the time of purchase.
                </p>
                <ul className="policy-list">
                    <li>The refund amount will equal the original purchase price minus any applicable deductions (such as restocking fees, missing documentation, etc.).</li>
                    <li>The Company may also deduct additional costs incurred during the verification and inspection process if discrepancies are found that necessitate further evaluation by third-party experts.</li>
                </ul>
            </div>

            <div className="policy-section">
                <h2>7. Exchanges</h2>
                <p className="policy-paragraph">
                    The Company may, at its discretion, offer exchanges for eligible Products. The exchange process will follow the same terms as the return process outlined herein. Exchanges must be requested within 3 days from the date of purchase.
                </p>
            </div>

            <div className="policy-section">
                <h2>8. Manufacturing Defects</h2>
                <p className="policy-paragraph">
                    A "Manufacturing Defect" refers to a fault or flaw arising from the manufacturing process that renders the Product unfit for its intended purpose. Manufacturing defects do not include any issues arising from wear and tear, improper handling, or customer misuse.
                </p>
            </div>

            <div className="policy-section">
                <h2>9. Miscellaneous</h2>
                <ul className="policy-list">
                    <li>The Company shall not be held liable for delays, failures, or inability to process returns, cancellations, or refunds due to events beyond its reasonable control, including but not limited to acts of God, natural disasters, government restrictions, war, strikes, pandemics, or any other unforeseen events constituting force majeure.</li>
                    <li>The Company reserves the right to modify, amend, or update this Policy at any time without prior notice. Any changes will take effect from the date they are posted on the Company’s website.</li>
                </ul>
            </div>
        </div>

    )
}

export default Return