import React, { useState } from 'react'
// import { Link, Outlet } from 'react-router-dom';
import Nav from './components/Nav/Nav';
import Footer from './components/Footer/Footer';
import up from './assets/icons/upPink.png'
import { useShoppingCart } from './context/ShoppingCartContext';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import axios from 'axios';
import { useEffect } from 'react';

const Layout = ({loveletters_ref}) => {


  axios.defaults.withCredentials = true
  const { auth } = useAuth();
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    // console.log('auth hai ki nahi',auth)
    const checkauthentication = async () => {
      try {

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/refresh`,);
        // console.log(response)
        const token = response.data.accessToken;
        // // Split the token and taken the second

        const base64Url = token?.split(".")[1];

        // // Replace "-" with "+"; "_" with "/"
        const base64 = base64Url?.replace("-", "+").replace("_", "/");

        const TokenDataWithoutToken = JSON.parse(window?.atob(base64));
        console.log('Response:', TokenDataWithoutToken);

        const Role = TokenDataWithoutToken.role

        const TokenData = { username: TokenDataWithoutToken.username, role: TokenDataWithoutToken.role, RawToken: token }

        try {
          if (Role) {
            console.log(Role, 'brkpnt 2')
            setAuth(TokenData);//isme role set nahi ho raha
            console.log(Role, 'brkpnt 3')
            // console.log(state.prev.pathname)
            navigate('/admindashboard');
          }
          else {
            console.log(Role, 'role not found')
          }
        } catch (e) {
          console.log('e', e);
        }

      } catch (e) { console.log(e) }
    }

    if (!auth.role) checkauthentication();
  }, [])


  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    // Show button when scrolled down more than 100px
    setIsVisible(scrollPosition > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const location = useLocation();


  return (

    <div className="App">

      {location.pathname != '/admindashboard' && <Nav loveletters_ref={loveletters_ref}/>}

      <Outlet />

      {isVisible &&
        <div className='fixed right-2 bottom-6 rounded-3xl bg-[var(--secondary)] text-white custom-shadow h-8 w-8 flex items-center justify-center cursor-pointer z-10'
          onClick={scrollToTop}>
          <img src={up} className='h-5 object-contain w-full' />
        </div>
      }

      {location.pathname != '/admindashboard' && <Footer loveletters_ref={loveletters_ref}/>}
    </div>

  )
}

export default Layout
