import React, { useEffect } from 'react'
import './JewelleryCare.css'
import { Link } from 'react-router-dom'

const JewelleryCare = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className='JewelleryCare'>

            <div>
                <button className='text-white'>JEWELLERY CARE</button>
                <div className='text-[10px] text-white text-left px-4 mt-4'><Link to='/' className='pinkHover'>HOME</Link> / CARE / JEWELLERY CARE</div>
            </div>

            <div className='BA_head'>JEWELLERY CARE</div>

            <div className='JC_Container'>
                <div>
                    <div>Our jewellery is designed to last for years, but even the finest pieces can tarnish and look dull without proper care. To keep your jewellery looking its best, follow these essential tips:</div>

                    <ul className='JC_bullets'>
                        <li>Protect your jewellery from bumps, scratches, chemicals, sunlight, extreme temperature and humidity.</li>
                        <li>Remove your pieces before you shower, bathe, swim (especially in chlorinated or salty water), or engage in activities like gardening or exercising.</li>
                        <li>Avoid exposing your jewellery to household cleaning products, such as bleach.</li>
                        <li>Keep your jewellery away from direct contact with perfume or hairspray to preserve its brilliance.</li>
                        <li>Store your jewellery in separate pouches or boxes to avoid scratches and tangling.</li>
                    </ul>
                </div>


                <div>
                    <div>To maintain your jewellery pieces, follow these cleaning practices regularly:</div>

                    <ul className='JC_bullets'>
                        <li>Opt for a gentle, non-abrasive cleaner suitable for your jewellery.</li>
                        <li>Use a soft toothbrush to gently clean hard-to-reach areas and remove dirt.</li>
                        <li>Rinse your jewellery with lukewarm water and dry it with a soft, lint-free cloth to avoid water spots and tarnishing.</li>
                    </ul>
                </div>
            </div>

            <div>
                HOW ABOUT ADDING SOME NEW <br />JEWELLERY TO CARE FOR?
            </div>

            <div className='max-w-[1100px] mx-auto w-full'>
                <div className='font-heading text-white text-[48px] text-left'>See More</div>
                <div className='flex gap-4'>
                    <div className='bg-white w-full h-[250px] relative'>
                        <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE NEW pendants</div>
                    </div>
                    <div className='bg-white w-full h-[250px] relative'>
                        <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE earrings</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default JewelleryCare