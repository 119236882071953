import React, { useEffect } from 'react'
import './SizeGuide.css'
import { Link } from 'react-router-dom'
import one from '../../assets/sizeguide/one.png';
import two from '../../assets/sizeguide/two.png';

const SizeGuide = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const ringSizes = [
        { diameter: 14.01, usSize: 3, indianSize: 4 },
        { diameter: 14.33, usSize: null, indianSize: 5 },
        { diameter: 14.5, usSize: 3.5, indianSize: null },
        { diameter: 14.65, usSize: null, indianSize: 6 },
        { diameter: 14.97, usSize: 4, indianSize: 7 },
        { diameter: 15.29, usSize: 4.5, indianSize: 8 },
        { diameter: 15.61, usSize: 5, indianSize: 9 },
        { diameter: 15.92, usSize: null, indianSize: 10 },
        { diameter: 16.1, usSize: 5.5, indianSize: null },
        { diameter: 16.24, usSize: null, indianSize: 11 },
        { diameter: 16.56, usSize: 6, indianSize: 12 },
        { diameter: 16.88, usSize: 6.5, indianSize: 13 },
        { diameter: 17.2, usSize: 7, indianSize: 14 },
        { diameter: 17.52, usSize: null, indianSize: 15 },
        { diameter: 17.7, usSize: 7.5, indianSize: null },
        { diameter: 17.83, usSize: null, indianSize: 16 },
        { diameter: 18.15, usSize: 8, indianSize: 17 },
        { diameter: 18.47, usSize: 8.5, indianSize: 18 },
        { diameter: 18.79, usSize: null, indianSize: 19 },
        { diameter: 19.0, usSize: 9, indianSize: null }
    ];

    const ringCircumferences = [
        { circumference: 44.88, usSize: 3, indianSize: 4 },
        { circumference: 46.10, usSize: null, indianSize: 5 },
        { circumference: 47.40, usSize: null, indianSize: 6 },
        { circumference: 48.00, usSize: 4, indianSize: 7 },
        { circumference: 48.70, usSize: 4.5, indianSize: 8 },
        { circumference: 50.00, usSize: 5, indianSize: 9 },
        { circumference: 51.20, usSize: null, indianSize: 10 },
        { circumference: 51.90, usSize: null, indianSize: 11 },
        { circumference: 53.10, usSize: 6, indianSize: 12 },
        { circumference: 54.40, usSize: 6.5, indianSize: 13 },
        { circumference: 55.10, usSize: 7, indianSize: 14 },
        { circumference: 56.30, usSize: null, indianSize: 15 },
        { circumference: 57.00, usSize: null, indianSize: 16 },
        { circumference: 58.30, usSize: 8, indianSize: 17 },
        { circumference: 58.90, usSize: 8.5, indianSize: 18 },
        { circumference: 60.20, usSize: null, indianSize: 19 }
    ];



    return (
        <div className='SizeGuide'>

            <div>
                <button className='text-white'>RING SIZE GUIDE</button>
                <div className='text-[10px] text-white text-left px-4 mt-4'><Link to='/' className='pinkHover'>HOME</Link> / CARE / RING SIZE GUIDE</div>
            </div>

            <div className='BA_head text-center'>RING SIZE GUIDE</div>

            <div className='text-white max-w-[500px] mx-auto mt-8'>
                Determining the right ring size is essential for ensuring a comfortable and secure fit. You can find your ring size using either an existing ring or a simple thread method. In case of any questions, we’d be more than happy to assist you (contact link).
            </div>

            <div className='flex gap-8 justify-evenly mt-4 flex-col sm:flex-row'>
                <div className='max-w-[500px]'>
                    <div>MEASURING WITH A PREVIOUSLY OWNED RING</div>
                    <div className='text-white'>
                        To use an existing ring for measurement, choose one that fits the intended finger comfortably. Measure the inner diameter of this ring with a ruler and refer to the size chart to identify your ring size.
                    </div>
                </div>

                <div className='h-[150px] mt-8'>
                    <img src={one} className='object-contain h-full mx-auto' />
                </div>
            </div>

            <div className='grid grid-cols-3 mt-8 max-w-[500px] mx-auto w-full text-center'>

                <div className=''>Diameter (mm)</div>
                <div className=''>US Size</div>
                <div className=''>Indian Size</div>

                {ringSizes && ringSizes.map((item) =>
                    <>
                        <div className='SG_row'>{item.diameter}</div>
                        <div className='SG_row'>{item.usSize}</div>
                        <div className='SG_row'>{item.indianSize}</div>
                    </>
                )}
            </div>

            <div className='flex gap-8 justify-evenly items-center flex-col sm:flex-row'>
                <div className='max-w-[500px]'>
                    <div>MEASURING WITH A THREAD</div>
                    <div className='text-white mt-4'>
                        Wrap a thread or strip of paper around the base of your finger where the ring will be worn. Don’t pull it too hard. Mark the point where the thread overlaps, then measure the length of the thread using a ruler or measuring tape. This is the circumference of your finger and refer to the size chart for your ring size.
                    </div>
                </div>

                <div className='h-[200px]'>
                    <img src={two} className='object-contain h-full' />
                </div>
            </div>


            <div className='grid grid-cols-3 mt-8 max-w-[500px] mx-auto w-full text-center mb-10'>

                <div className=''>Circumference (mm)</div>
                <div className=''>US Size</div>
                <div className=''>Indian Size</div>

                {ringCircumferences && ringCircumferences.map((item) =>
                    <>
                        <div className='SG_row'>{item.circumference}</div>
                        <div className='SG_row'>{item.usSize}</div>
                        <div className='SG_row'>{item.indianSize}</div>
                    </>
                )}
            </div>

        </div>
    )
}

export default SizeGuide