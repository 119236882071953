import React, { useState, useEffect } from 'react'
import './Cart.css'
import { useShoppingCart } from '../../context/ShoppingCartContext';
import CartItem from '../CartItem/CartItem';
import menuItems from '../../utils/products.json'
import { Link, useParams } from 'react-router-dom';
import icon from '../../assets/icons/cart.svg'
import axios from 'axios';

const Cart = () => {


    const { cartQuantity, cartIsOpen, setCartIsOpen, cartItems } = useShoppingCart();

    const [tableNum, setTableNum] = useState();

    const [totalPrice, setTotalPrice] = useState();

    const [products, setProducts] = useState([])

    useEffect(() => {

        const getproducts = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)

                console.log(data.data);
                setProducts(data.data);
            }
            catch (error) { console.log('There was an error getting the love letters!', error); }
        }

        getproducts();

    }, [])

    useEffect(() => {
        if (products.length && cartItems.length) {
            setTotalPrice(
                cartItems.reduce((total, cartItem) => {
                    const item = products.find(i => i._id === cartItem.id);

                    // Ensure that item and its price exist
                    // return total + ((item?.customPrices[0]?.price || 0) * cartItem.quantity);
                }, 0)
            );
        }
    }, [cartItems, products]);

    const submitOrder = async (e) => {
        e.preventDefault();

        console.log(cartItems, totalPrice, tableNum)

        if (cartItems, totalPrice, tableNum) {

            try {


            }
            catch (err) { console.log(err); }
        }

    }

    return (
        <>
            <div className='CartSlider'>
                <div className='Cart font-body'>

                    <div>

                        <div className='closeCartBtn' onClick={() => setCartIsOpen(false)}>×</div>

                        <div className='cartIcon mb-2'><img src={icon} /></div>
                        <div className='font-heading text-[32px] text-[var(--secondary)] text-center'>
                            CART ({cartQuantity})
                        </div>

                        {cartItems.length > 0 ?
                            <div className='flex flex-col mt-2 cartItemsList gap-4'>
                                {cartItems && cartItems.map((item, i) => (
                                    <CartItem key={i} item={item} products={products} />
                                ))
                                }
                            </div>
                            :
                            <div className='font-subheading text-xl text-[var(--secondary)] text-center'>
                                Your Shopping Cart is Empty
                            </div>
                        }

                    </div>

                    <div className='PlaceOrderBtns'>
                        {cartItems.length > 0 ?
                            <>
                                <div className='flex justify-between gap-4 text-[20px]'>
                                    <div>SUBTOTAL</div>
                                    <div>INR {totalPrice}</div>
                                </div>

                                <Link to='/checkout'>
                                    <button
                                        className='cart_actionBtn'>
                                        checkout
                                    </button>
                                </Link>
                            </>

                            :
                            <Link to='/collection'>
                                <button className='cart_actionBtn'>
                                    explore collection
                                </button>
                            </Link>
                        }
                    </div>



                </div>
            </div >

        </>
    )
}

export default Cart