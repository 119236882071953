import React from 'react'
import './Checkout.css'
import { useShoppingCart } from '../../context/ShoppingCartContext'


const CheckoutItem = ({ item, products }) => {

    const { removeFromCart, decreaseCartQuantity, increaseCartQuantity, getItemQuantity } = useShoppingCart();
    const cartItem = products.find(i => i._id === item.id)
    if (cartItem == null) return null

    const quantity = getItemQuantity(item.id);

    console.log(item)

    return (
        <div className='CheckoutItem'>
            <div className='checkoutItemImg'>
                <img src={cartItem.imageURLs[0]?.url} alt="" />
            </div>

            <div className='checkoutItemInfo'>
                <div className='text-[var(--primary)] text-2xl font-heading uppercase'>
                    {cartItem.name}
                </div>
                <div className=''>
                    {cartItem.code}
                </div>
                
                <div className='font-bold'>
                    {/* INR {cartItem.customPrices[0].price} /-   */}
                    Qty: {quantity}
                </div>
            </div>
        </div>
    )
}

export default CheckoutItem