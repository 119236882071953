import React, { useState, useEffect } from 'react'
import './Checkout.css'
import { useShoppingCart } from '../../context/ShoppingCartContext';
import { Link, useParams } from 'react-router-dom';
import icon from '../../assets/icons/cart.svg'
import axios from 'axios';
import CheckoutItem from './CheckoutItem';

const Checkout = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { cartQuantity, cartIsOpen, setCartIsOpen, cartItems } = useShoppingCart();

    const [tableNum, setTableNum] = useState();

    const [totalPrice, setTotalPrice] = useState();

    const [products, setProducts] = useState([])

    useEffect(() => {

        const getproducts = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)

                console.log(data.data);
                setProducts(data.data);
            }
            catch (error) { console.log('There was an error getting the love letters!', error); }
        }

        getproducts();

    }, [])

    useEffect(() => {
        if (products.length && cartItems.length) {
            setTotalPrice(
                cartItems.reduce((total, cartItem) => {
                    const item = products.find(i => i._id === cartItem.id);

                    // Ensure that item and its price exist
                    // return total + ((item?.customPrices[0]?.price || 0) * cartItem.quantity);
                }, 0)
            );
        }
    }, [cartItems, products]);

    const submitOrder = async (e) => {
        e.preventDefault();

        console.log(cartItems, totalPrice, tableNum)

        if (cartItems, totalPrice, tableNum) {

            try {


            }
            catch (err) { console.log(err); }
        }

    }

    return (

        // <div className='CartSlider'>
        <div className='Checkout font-body'>

            <div className='bg-white p-8 pt-4 flex-grow custom-shadow'>

                <div className='closeCheckoutBtn'>
                    <Link to='/collection'>Browse Collection</Link>
                </div>

                {cartItems.length > 0 ?
                    <>
                        <div className='font-subheading text-3xl'>
                            Shopping Cart
                        </div>

                        <div className='text-sm mt-1'>
                            the pieces you've liked
                        </div>

                        <div className='flex flex-col mt-8'>
                            {cartItems && cartItems.map((item, i) => (
                                <CheckoutItem key={i} item={item} products={products} />
                            ))
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='font-subheading text-3xl'>
                            Your Shopping Cart is Empty
                        </div>

                        <div className='text-lg mt-4'>
                            <Link to='/collection'>Shop Here</Link>
                        </div>

                    </>
                }

            </div>


            {cartItems.length > 0 ?

                <div className='PlaceOrderBtnscheckout'>

                    <div className='flex gap-2 items-center text-lg'>
                        <div className='font-subheading font-bold'>SubTotal ({cartQuantity} items) :</div>
                        <div className='text-xl'>${totalPrice}</div>

                    </div>


                    <button onClick={(e) => submitOrder(e)} className='font-bold py-2 px-6 border-2 border-[var(--primary)] hover:bg-[var(--primary)] hover:text-white'>
                        Place Order
                    </button>
                </div>

                :
                <div className='w-[300px]'>

                </div>
            }
        </div>
        // </div>
    )
}

export default Checkout