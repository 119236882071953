import React, { useEffect, useState } from 'react'
import './ProductDisplay.css'
import menuList from '../../../utils/products.json'
import Product from '../../Collection/Product'
import { Link, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import axios from 'axios';


const ProductDisplay = () => {

    const location = useLocation();
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);

    // Single state for the selected category
    const [selectedCategory, setSelectedCategory] = useState('All');


    useEffect(() => {

        const getproducts = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)

                console.log(data.data);
                setFilteredProducts(data.data);
                setAllProducts(data.data);
            }
            catch (error) { console.log('There was an error getting the products!', error); }
        }

        getproducts();

    }, [])

    // Filter products based on selected category
    useEffect(() => {
        if (selectedCategory === 'All') {
            setFilteredProducts(allProducts);  // Show all products
        } else {
            const filtered = allProducts.filter(product => product.category === selectedCategory);
            setFilteredProducts(filtered);  // Show filtered products
        }
    }, [selectedCategory, allProducts]);


    return (
        <div className={location.pathname == '/' ? 'ProductDisplay' : 'ProductDisplayPortable'}>

            {location.pathname == '/' &&
                <div className='flex gap-4 lg:gap-8 px-0 sm:px-[0px]'>
                    <button
                        className={selectedCategory === 'All' ? 'PD_shopBtns mobile selected' : 'PD_shopBtns mobile'}
                        onClick={() => setSelectedCategory('All')}
                    >
                        Shop all products
                    </button>

                    <button
                        className={selectedCategory === 'Rings' ? 'PD_shopBtns selected' : 'PD_shopBtns'}
                        onClick={() => setSelectedCategory('Rings')}
                    >
                        Shop rings
                    </button>

                    <button
                        className={selectedCategory === 'Earrings' ? 'PD_shopBtns selected' : 'PD_shopBtns'}
                        onClick={() => setSelectedCategory('Earrings')}
                    >
                        Shop earrings
                    </button>


                    <button
                        className={selectedCategory === 'Wristwear' ? 'PD_shopBtns selected' : 'PD_shopBtns'}
                        onClick={() => setSelectedCategory('Wristwear')}
                    >
                        Shop wristwear
                    </button>
                    
                    <button
                        className={selectedCategory === 'Neckpieces' ? 'PD_shopBtns selected' : 'PD_shopBtns'}
                        onClick={() => setSelectedCategory('Neckpieces')}
                    >
                        Shop neckpieces
                    </button>


                </div>
            }

            <Swiper
                slidesPerView={4}
                spaceBetween={10}
                loop={filteredProducts.length > 4}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                //     pauseOnMouseEnter: true
                // }}
                speed={1000}
                // pagination={{
                //   clickable: true,
                // }}
                navigation={true}
                breakpoints={{
                    250: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                    },
                    370: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    800: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                }}
                centeredSlides={false}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"
            >
                {filteredProducts.length > 0 && filteredProducts.map((item, i) =>

                    <SwiperSlide key={i}>
                        {/* <Link to={`/product/${item.code}`}> */}
                        <Product item={item} />
                        {/* </Link> */}
                    </SwiperSlide>
                )}

            </Swiper>

            {filteredProducts.length === 0 &&
                <div>No Products to Show</div>
            }

            {/* <div className='productCarousel'>
                {products && products.map((item, i) =>
                        <Product item={item} key={i} />
                )}
            </div> */}
        </div>
    )
}

export default ProductDisplay