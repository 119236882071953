import React, { useEffect, useState } from 'react'
import down from '../../assets/icons/down.png'
import virtualwhite from '../../assets/icons/virtual.png'
import virtualpink from '../../assets/icons/virtualpink.png'
import walkinwhite from '../../assets/icons/walkin.png'
import walkinpink from '../../assets/icons/walkinpink.png'
import './BookAppointment.css'
import { Link } from 'react-router-dom'

const BookAppointment = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [hover, setHover] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [purpose, setPurpose] = useState('')
    const [virtual, setVirtual] = useState(false)
    const [walkin, setWalkin] = useState(false)


    return (
        <div className='BookAppointment'>
            <div>
                <button className='text-white'>BOOK AN APPOINTMENT</button>
                <div className='text-[10px] text-white text-left px-4 mt-4'><Link to='/' className='pinkHover'>HOME</Link> / BOOK AN APPOINTMENT</div>
            </div>

            <div className='BA_head'>BOOK AN APPOINTMENT</div>

            <div className='BA_container'>
                <div>
                    <div className='BA_head'>1. SELECT A MODE OF APPOINTMENT</div>
                    <div className='flex justify-evenly'>
                        <div className='flex flex-col items-center justify-center gap-4 w-full h-20 cursor-pointer'
                            onMouseEnter={e => setHover(true)}
                            onMouseLeave={e => setHover(false)}
                            onClick={()=> {setVirtual(false); setWalkin(true)}}
                        >
                            <div className='h-6'>
                                <img src={(hover || walkin) ? walkinpink : walkinwhite} className='max-h-[100%] h-full object-contain' />
                            </div>
                            <div className={(hover || walkin) ? 'pinkHover hover' : 'pinkHover'}>WALK IN</div>
                        </div>
                        <div className='flex flex-col items-center justify-center gap-4 w-full h-20 cursor-pointer'
                            onMouseEnter={e => setHover2(true)}
                            onMouseLeave={e => setHover2(false)}
                            onClick={()=> {setVirtual(true); setWalkin(false)}}
                        >
                            <div className='h-5'>
                                <img src={(hover2 || virtual) ? virtualpink : virtualwhite} className='max-h-[100%] h-full object-contain' />
                            </div>
                            <div className={(hover2 || virtual) ? 'pinkHover hover' : 'pinkHover'}>VIRTUAL</div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='BA_head'>2. PURPOSE OF YOUR VISIT</div>
                    <div className='flex justify-evenly gap-16 sm:gap-4 w-full mt-4 flex-col sm:flex-row'>
                        <div>
                            <div>
                                <input
                                    type="radio"
                                    name='purpose'
                                    id='PSE'
                                    value="PSE"
                                    onClick={(e) => setPurpose(e.target.value)}
                                    checked={purpose === "PSE"}
                                />
                                <label htmlFor="yes" className='ml-3 cursor-pointer'>PERSONALISED SHOPPING EXPERIENCE</label>
                            </div>

                            {/* <div className='mt-10 flex flex-col'>
                                <div className='font-subheading text-xl border-b border-b-[1px] border-white py-2 mb-3 text-center flex items-center justify-evenly'>
                                    <div>specify your needs</div>
                                    <span className='faqArrows'><img src={down} /> </span>
                                </div>
                                <div className='flex flex-col gap-1 self-center'>
                                    <div className='pinkHover'></div>
                                    <div className='pinkHover'>CUSTOM ENGAGEMENT RING</div>
                                    <div className='pinkHover'>CUSTOM PRODUCT DESIGN</div>
                                </div>
                            </div> */}

                            {/* {purpose === "PSE" && */}
                            <div className={purpose === "PSE" ? 'w-full mt-10' : 'w-full mt-10 hidden sm:block'}>
                                <div className='text-lg !font-subheading text-center'>specify your needs</div>

                                <select className='input font-subheading lowercase w-[100%] mt-4 text-[var(--primary)]'>
                                    <option className='!pinkHover font-body option uppercase'>DISCOVER PRODUCTS</option>
                                    <option className='pinkHover font-body option uppercase'>CUSTOM ENGAGEMENT RING</option>
                                    <option className='pinkHover font-body option uppercase'>CUSTOM PRODUCT DESIGN</option>
                                </select>
                            </div>
                            {/* } */}
                        </div>

                        <div>
                            <div>
                                <input
                                    type="radio"
                                    name='purpose'
                                    id='CCS'
                                    value="CCS"
                                    onClick={(e) => setPurpose(e.target.value)}
                                    checked={purpose === "CCS"}
                                />
                                <label htmlFor="yes" className='ml-3 cursor-pointer'>CUSTOMER CARE SERVICES</label>
                            </div>

                            {/* {purpose === "CCS" && */}
                            <div className={purpose === "CCS" ? 'w-full mt-10' : 'w-full mt-10 hidden sm:block'}>
                                <div className='text-lg !font-subheading text-center'>specify your needs</div>

                                <select className='input font-subheading lowercase w-[100%] mt-4 text-[var(--primary)]'>
                                    <option className='!pinkHover font-body option uppercase'>EXCHANGE/ RETURN</option>
                                    <option className='pinkHover font-body option uppercase'>GETTING TO KNOW LAB DIAMONDS</option>
                                </select>
                            </div>
                            {/* } */}
                        </div>

                    </div>

                    <div className='flex justify-evenly gap-4 mt-8 items-center'>
                        <div className='font-subheading'>do you have a price range in mind?</div>
                        <input className='input w-[50%] max-w-[250px]'
                            placeholder='INR' />
                    </div>
                </div>

                <div>
                    <div className='BA_head'>3. CHOOSE A DATE</div>
                </div>

                <div>
                    <div className='BA_head'>4. CHOOSE A TIME</div>
                    <div className='timeSlots my-4'>
                        <div className='pinkHover'>TIME SLOT 1</div>
                        <div className='pinkHover'>TIME SLOT 2</div>
                        <div className='pinkHover'>TIME SLOT 3</div>
                        <div className='pinkHover'>TIME SLOT 4</div>
                        <div className='pinkHover'>TIME SLOT 5</div>
                        <div className='pinkHover'>TIME SLOT 6</div>
                        <div className='pinkHover'>TIME SLOT 7</div>
                        <div className='pinkHover'>TIME SLOT 8</div>
                    </div>
                </div>

                <div>
                    <div className='BA_head'>5. PERSONAL INFORMATION</div>

                    <div className='personalInfo'>
                        <div className='relative'>
                            <div>FIRST NAME*</div>
                            <input className='input w-[90%] text-[var(--primary)] my-2 font-subheading'
                                placeholder='' />

                            {/* <div className='absolute top-[-16px] right-0 left-0 text-[var(--primary)] text-[10px]'>first name is required. please enter your first name*</div> */}
                        </div>

                        <div className='relative'>
                            <div>LAST NAME*</div>
                            <input className='input w-[90%] text-[var(--primary)] my-2 font-subheading'
                                placeholder='' />

                            {/* <div className='absolute top-[-16px] right-0 left-0 text-[var(--primary)] text-[10px]'>first name is required. please enter your first name*</div> */}
                        </div>

                        <div className='relative'>
                            <div>EMAIL ID*</div>
                            <input className='input w-[90%] text-[var(--primary)] my-2 font-subheading'
                                placeholder='' />

                            {/* <div className='absolute top-[-16px] right-0 left-0 text-[var(--primary)] text-[10px]'>first name is required. please enter your first name*</div> */}
                        </div>

                        <div className='relative'>
                            <div>PHONE NUMBER*</div>
                            <input className='input w-[90%] text-[var(--primary)] my-2 font-subheading'
                                placeholder='' />

                            {/* <div className='absolute top-[-24px] right-0 left-0 text-[var(--primary)] text-[10px]'>phone number is required. please enter your phone number*</div> */}
                        </div>

                    </div>
                </div>

                <div>
                    <div className='BA_head'>6. ADDITIONAL INFORMATION/ ANYTHING YOU WOULD LIKE US TO KNOW</div>
                    <input
                        className='input w-[100%] !text-left text-[var(--primary)] my-4 mx-auto font-subheading'
                        placeholder=''
                    />

                </div>

                <button className='w-[200px] mx-auto mb-[-80px] mt-8'>Submit</button>
            </div>


            <div className='flex flex-col items-center gap-2 mt-20 text-left'>
                <div className='max-w-[300px]'>
                    THANK YOU FOR CONTACTING US. WE WILL BE IN TOUCH VIA EMAIL AND PHONE. FOR ANY URGENT REQUIRMENT PLEASE GO TO OUR CONTACT US PAGE.
                    <br /><br />Additionally you can browse through our catalogue and pick out the ones you like for reference.
                </div>
                <Link to='/contactus'><button className='w-[160px] mt-4'>contact us</button></Link>
            </div>

        </div>
    )
}

export default BookAppointment