import React, { useEffect } from 'react'
import './CustomEngRings.css'
import down from '../../assets/icons/down.png'
import { Link } from 'react-router-dom'

const CustomEngRings = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className='CustomEngRings'>

            <div>
                <button className='text-white'>CUSTOM ENGAGEMENT RINGS</button>
                <div className='text-[10px] text-white text-left px-4 mt-4'><Link to='/' className='pinkHover'>HOME</Link> / SHOP / CUSTOM ENGAGEMENT RINGS</div>
            </div>

            <div className='BA_head'>CUSTOM ENGAGEMENT RINGS</div>

            <div className='BA_container'>

                <div className='font-bodyone sm:text-[18px]'>
                    Moheera stands for creating an experience around the jewellery piece, not just delivering it. If you are a couple searching for the perfect engagement ring, we are excited to o er a private session to explain the 4Cs and various engagement ring settings, allowing you to explore different designs until you find the one that suits you best. We look forward to working closely with you from start to finish, creating your dream ring that is as beautiful as it is meaningful.
                </div>

                <button className='w-fit mx-auto'>Start the process</button>

                <div>
                    <div className='BA_head'>PERSONAL INFORMATION</div>

                    <div className='personalInfo'>
                        <div className='relative'>
                            <div>FIRST NAME*</div>
                            <input className='input w-[90%] text-[var(--primary)] my-2 font-subheading'
                                placeholder='' />

                            {/* <div className='absolute top-[-16px] right-0 left-0 text-[var(--primary)] text-[10px]'>first name is required. please enter your first name*</div> */}
                        </div>

                        <div className='relative'>
                            <div>LAST NAME*</div>
                            <input className='input w-[90%] text-[var(--primary)] my-2 font-subheading'
                                placeholder='' />

                            {/* <div className='absolute top-[-16px] right-0 left-0 text-[var(--primary)] text-[10px]'>first name is required. please enter your first name*</div> */}
                        </div>

                        <div className='relative'>
                            <div>EMAIL ID*</div>
                            <input className='input w-[90%] text-[var(--primary)] my-2 font-subheading'
                                placeholder='' />

                            {/* <div className='absolute top-[-16px] right-0 left-0 text-[var(--primary)] text-[10px]'>first name is required. please enter your first name*</div> */}
                        </div>

                        <div className='relative'>
                            <div>PHONE NUMBER*</div>
                            <input className='input w-[90%] text-[var(--primary)] my-2 font-subheading'
                                placeholder='' />

                            {/* <div className='absolute sm:top-[-16px] top-[-24px] right-0 left-0 text-[var(--primary)] text-[10px]'>phone number is required. please enter your phone number*</div> */}
                        </div>

                    </div>
                </div>

                <div className='flex justify-between gap-8'>
                    <div className='w-full'>
                        <div className='BA_head'>UPLOAD ANY REFERENCE IMAGES</div>

                        {/* <select className='input font-subheading lowercase w-[90%] mt-4'>
                            <option className='!pinkHover font-body option'>13K SOLITAIRE</option>
                            <option className='pinkHover font-body option'>SMALLER DIAMONDS</option>
                            <option className='pinkHover font-body option'>BANDS</option>
                        </select> */}
                        <input
                            // onChange={
                            //     e => setFile1(e.target.files[0])
                            // }
                            type="file"
                            accept="image/*"
                            className='input formInput'
                            name='image'
                        />
                    </div>

                    <div className='w-full'>
                        <div className='BA_head'>BUDGET</div>

                        {/* <select className='input font-subheading lowercase w-[90%] mt-4'>
                            <option className='!pinkHover font-body option'>GOLD</option>
                            <option className='pinkHover font-body option'>SILVER</option>
                        </select> */}
                        <input className='input w-full text-[var(--primary)] font-subheading mt-[12px]'
                                placeholder='' />
                    </div>
                </div>


                <div className='mt-6'>
                    <div className='BA_head'>MESSAGE</div>
                    <input className='input w-[100%] text-[var(--primary)] my-4 mx-auto font-subheading'
                        placeholder='' />
                </div>

                <button className='w-[200px] mx-auto mb-[-80px] mt-8'>Submit</button>

            </div>


            <div className='flex flex-col items-center gap-2 text-left mt-20'>
                <div>
                    HIT US UP AND LET'S WORK TOGETHER TO <br />CREATE A STUNNING PIECE JUST FOR YOU!
                </div>

                <div className='flex justify-evenly w-full my-10 flex-col sm:flex-row'>
                    <div className='flex flex-col items-center gap-2'>
                        <div className='font-subheading text-white mt-4'>
                            contact us or book a discovery call
                        </div>
                        <Link to='/contactus'><button className='text-white w-[160px]'>contact us</button></Link>
                    </div>

                    <div className='flex flex-col items-center gap-2'>
                        <div className='font-subheading text-white mt-4'>
                            know more about our bespoke process
                        </div>
                        <Link to='/bespokeprocess'><button className='text-white w-[160px]'>bespoke process</button></Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CustomEngRings